import { useState, useEffect } from "react";
import { useLocation, Link } from "wouter";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { useLogin, useModal } from "./Login.jsx";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLoginIcon from "./GoogleIcon.jsx";
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const proxy = "https://api.gammacities.com/api";

const Information = ({ text, setErrors }) => {
    const [isShown, setIsShown] = useState(true);

    const handleClose = () => {
        setIsShown(false);
        setErrors({});
    };

    return (
        <>
            <AnimatePresence>
                {isShown && (
                    <motion.div
                        initial={{
                            opacity: 0,
                        }}
                        animate={{
                            opacity: 1,
                        }}
                        exit={{
                            opacity: 0,
                        }}
                        className="register__message"
                    >
                        <div
                            onClick={handleClose}
                            className="register__message--bg"
                        />
                        <div className="register__message--content">
                            <div className="register__message--icon">
                                <svg
                                    width={50}
                                    height={50}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM11 14V16H13V14H11ZM8.56731 8.81346L10.5288 9.20577C10.6656 8.51823 11.2723 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5C13.5 10.3284 12.8284 11 12 11H11V13H12C13.933 13 15.5 11.433 15.5 9.5C15.5 7.567 13.933 6 12 6C10.302 6 8.88637 7.20919 8.56731 8.81346Z"
                                        fill="rgba(255,255,255,1)"
                                    ></path>
                                </svg>
                            </div>
                            <p className="register__message--subtitle">
                                {text}
                            </p>
                            <button
                                className="register__message--close"
                                onClick={handleClose}
                            ></button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

const RegisterForm = () => {
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };
    const [location, navigate] = useLocation();

    const user = useLogin((state) => state.user);
    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);
    const initialFormValues = {
        name: "",
        userName: "",
        email: "",
        password: "",
        confirmPassword: "",
        confirmedTerms: false,
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formValues.email.match(emailRegex)) {
            newErrors.email = "Please enter a valid email address";
            isValid = false;
        }

        if (formValues.name.length < 3 || formValues.name.length > 30) {
            newErrors.name = "Name must be between 3 and 30 characters";
            isValid = false;
        }
        if (formValues.userName.length < 3 || formValues.userName.length > 10) {
            newErrors.userName = "Username must be between 3 and 10 characters";
            isValid = false;
        }

        if (formValues.password.length < 8) {
            newErrors.password = "password has to be more than 7 characters";
            isValid = false;
        }

        if (formValues.password != formValues.confirmPassword) {
            newErrors.password = "Password dismatches";
            isValid = false;
        }
        if (!isValidPhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = 'Invalid phone number';
            isValid = false;
          }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission logic here
            const data = new FormData();
            data.append("name", formValues.name);
            data.append("email", formValues.email);
            data.append("username", formValues.userName);
            data.append("password", formValues.password);
            data.append("phoneNumber", phoneNumber);
            const referrerCode = new URLSearchParams(
                window.location.search
            ).get("referralCode");

            if(referrerCode){
                data.append("referrerCode", referrerCode);
            }
            const config = {
                method: "post",
                url: `${proxy}/auth/register`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                withCredentials: true,
            };
            if (!recaptchaValue) {
                alert("Please complete the reCAPTCHA verification.");
                return; // Stop form submission
            }
            setErrors({});
            axios(config)
                .then((response) => {
                    const redirect_url = new URLSearchParams(
                        window.location.search
                    ).get("redirect_url");

                    navigate(
                        `/verify${
                            redirect_url ? "?redirect_url=" + redirect_url : ""
                        }`
                    );
                    // Reset form values to initial state
                    setFormValues(initialFormValues);
                })
                .catch((error) => {
                    const newErrors = {};
                    newErrors.form = error.response.data.message;
                    setErrors(newErrors);
                });
        }
    };

    return (
        <>
            <div className="login__form">
                <form onSubmit={handleSubmit}>
                    <div className="login__inputs">
                        <input
                            required
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formValues.name}
                            onChange={handleChange}
                            className="login__input"
                        />
                        {errors.name && (
                            <Information
                                text={errors.name}
                                setErrors={setErrors}
                            />
                        )}
                    </div>
                    <div className="login__inputs">
                        <input
                            required
                            type="text"
                            name="userName"
                            placeholder="Username"
                            value={formValues.userName}
                            onChange={handleChange}
                            className="login__input"
                        />
                        {errors.userName && (
                            <Information
                                text={errors.userName}
                                setErrors={setErrors}
                            />
                        )}
                    </div>
                    <div className="login__inputs">
                        <PhoneInput
                            id="phoneNumber"
                            required
                            defaultCountry="TR"
                            initialValueFormat="national"
                            placeholder={'Phone Number'}
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                        />
                        {errors.phoneNumber && (
                            <Information
                                text={errors.phoneNumber}
                                setErrors={setErrors}
                            />
                        )}
                    </div>
                    <input
                        required
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formValues.email}
                        onChange={handleChange}
                        className="register__input"
                    />
                    {errors.email && <p>{errors.email}</p>}
                    <div className="register__inputs">
                        <input
                            required
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}
                            className="register__input"
                        />
                        <input
                            required
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formValues.confirmPassword}
                            onChange={handleChange}
                            className="register__input"
                        />
                        {errors.confirmPassword && (
                            <Information
                                text={errors.confirmPassword}
                                setErrors={setErrors}
                            />
                        )}
                    </div>
                    {errors.password && (
                        <Information
                            text={errors.password}
                            setErrors={setErrors}
                        />
                    )}
                    <div className="register__inputs">
                        <input
                            required
                            id="confirmedTerms"
                            type="checkbox"
                            name="confirmedTerms"
                            value={formValues.confirmPassword}
                            onChange={handleChange}
                        />
                        <label
                            htmlFor="confirmedTerms"
                            style={{ fontSize: 11 }}
                        >
                            By checking this box you accept the Gammacities
                            <a
                                href="/disclosure"
                                target="_blank"
                                className="register__link"
                            >
                                {" "}
                                Terms of Use{" "}
                            </a>
                            ,
                            <a
                                href="/consent"
                                target="_blank"
                                className="register__link"
                            >
                                {" "}
                                acknowledge the Privacy Policy and Cookie Policy{" "}
                            </a>
                            and
                            <a
                                href="/pdf/PDPL.pdf"
                                target="_blank"
                                className="register__link"
                            >
                                {" "}
                                PDPL{" "}
                            </a>
                        </label>
                    </div>
                    <div
                        style={{
                            width: "304px",
                            margin: "10px auto",
                            marginBottom: "15px",
                        }}
                    >
                        <ReCAPTCHA
                            sitekey="6Lcy2-InAAAAANMVvIHJkbQtEOaJnuKMKUINcdQc"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    <button type="submit" className="login__submit">
                        Register
                    </button>
                    {errors.form && (
                        <Information text={errors.form} setErrors={setErrors} />
                    )}
                </form>
                <div className="register__login">
                    Already have an account?
                    <Link href="/login">Log in</Link>
                </div>

                <GoogleLoginIcon />
            </div>
        </>
    );
};

const LoginForm = () => {
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const initialFormValues = {
        email: "",
        password: "",
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [location, navigate] = useLocation();
    const user = useLogin((state) => state.user);
    const loggedIn = useLogin((state) => state.loggedIn);
    const reference = localStorage.getItem("gamma-reference");
    const changeModal = useModal((state) => state.changeModal);

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formValues.email.match(emailRegex)) {
            newErrors.email = "Please enter a valid email address";
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission logic here
            const data = new FormData();
            data.append("email", formValues.email);
            data.append("password", formValues.password);
            const config = {
                method: "post",
                url: `${proxy}/auth/login`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
                withCredentials: true,
            };
            if (!recaptchaValue) {
                alert("Please complete the reCAPTCHA verification.");
                return; // Stop form submission
            }
            axios(config)
                .then((response) => {
                    setErrors({});
                    const token = response.data.token.split(".")[1];
                    const data = JSON.parse(atob(token));
                    if (data.isVer) {
                        loggedIn(response.data);

                        const redirect_url = new URLSearchParams(
                            window.location.search
                        ).get("redirect_url");
                        if (redirect_url) {
                            window.location.href = `${redirect_url}`;
                        }
                        changeModal(true);
                        reference ? navigate(`${reference}`) : navigate("/");
                    }
                })
                .catch((error) => {
                    const newErrors = {};
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message ===
                            "User not verified. Complete verification to proceed."
                    ) {
                        navigate("/verify");
                    } else {
                        newErrors.form = error.response.data.message;
                    }
                    setErrors(newErrors);
                });
            setFormValues(initialFormValues);
        }
    };

    return (
        <>
            <div className="login__form">
                <form onSubmit={handleSubmit}>
                    <label className="login__label">
                        Email
                        <input
                            type="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                            className="login__input"
                        />
                        {errors.email && (
                            <Information
                                text={errors.email}
                                setErrors={setErrors}
                            />
                        )}
                    </label>
                    <label className="login__label">
                        Password
                        <input
                            type="password"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}
                            className="login__input"
                        />
                    </label>
                    <div
                        style={{
                            width: "304px",
                            margin: "10px auto",
                            marginBottom: "15px",
                        }}
                    >
                        <ReCAPTCHA
                            sitekey="6Lcy2-InAAAAANMVvIHJkbQtEOaJnuKMKUINcdQc"
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    {errors.form && (
                        <Information text={errors.form} setErrors={setErrors} />
                    )}
                    <button type="submit" className="login__submit">
                        Login
                    </button>
                </form>

                <div className="login__register">
                    <p>
                        No account yet?
                        <Link href="/register" style={{ margin: "auto 4px" }}>
                            Sign up
                        </Link>
                    </p>
                    <Link href="/reset">Reset password</Link>
                </div>
                <GoogleLoginIcon />
            </div>
        </>
    );
};
const VerifyForm = () => {
    const initialFormValues = {
        email: "",
        code: "",
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [location, navigate] = useLocation();
    const loggedIn = useLogin((state) => state.loggedIn);
    const changeModal = useModal((state) => state.changeModal);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        const data = new FormData();
        data.append("code", formValues.code);
        data.append("email", formValues.email);
        const config = {
            method: "post",
            url: `${proxy}/auth/verify-email`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
            withCredentials: true,
        };
        axios(config)
            .then((response) => {
                setErrors({});
                loggedIn(response.data.token);
                const redirect_url = new URLSearchParams(
                    window.location.search
                ).get("redirect_url");
                if (redirect_url) {
                    window.location.href = `${redirect_url}`;
                }
                changeModal(true);
                navigate("/");
            })
            .catch((error) => {
                const newErrors = {};
                newErrors.code = error.response.data.message;
                setErrors(newErrors);
            });
        setFormValues(initialFormValues);

        // Reset form values to initial state
    };

    return (
        <>
            <div className="login__form">
                <form onSubmit={handleSubmit}>
                    <div className="login__inputs">
                        <label className="login__label">
                            Please check your email. We just sent you a code.
                        </label>
                        <label className="login__label">
                            Email
                            <input
                                type="text"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                                className="login__input"
                            />
                            {errors.email && (
                                <Information
                                    text={errors.email}
                                    setErrors={setErrors}
                                />
                            )}
                        </label>
                    </div>
                    <div className="login__inputs">
                        <label className="login__label">
                            Code
                            <input
                                type="text"
                                name="code"
                                value={formValues.code}
                                onChange={handleChange}
                                className="login__input"
                            />
                            {errors.code && (
                                <Information
                                    text={errors.code}
                                    setErrors={setErrors}
                                />
                            )}
                        </label>
                    </div>

                    <button type="submit" className="login__submit">
                        Send
                    </button>
                    {errors.form && (
                        <Information text={errors.form} setErrors={setErrors} />
                    )}
                </form>
                <div className="register__login">
                    Already verified your account?
                    <Link href="/login">Log in</Link>
                </div>
            </div>
        </>
    );
};

const ResetPasswordRequest = () => {
    const initialFormValues = {
        email: "",
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [location, navigate] = useLocation();
    const user = useLogin((state) => state.user);
    const loggedIn = useLogin((state) => state.loggedIn);
    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here

        const data = new FormData();
        data.append("email", formValues.email);
        const config = {
            method: "post",
            url: `${proxy}/auth/forgot-password`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then((response) => {
                setErrors({});
                console.log(response.data);
                const newErrors = {};
                newErrors.email = response.data.message;
                setErrors(newErrors);
                // loggedIn(response.data)
                // navigate('/');
            })
            .catch((error) => {
                const newErrors = {};
                newErrors.email = error.response.data.message;
                setErrors(newErrors);
            });
        setFormValues(initialFormValues);

        // Reset form values to initial state
    };

    return (
        <>
            <div className="login__form">
                <form onSubmit={handleSubmit}>
                    <div className="login__inputs">
                        <input
                            required
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formValues.email}
                            onChange={handleChange}
                            className="login__input"
                        />
                        {errors.email && (
                            <Information
                                text={errors.email}
                                setErrors={setErrors}
                            />
                        )}
                    </div>

                    <button type="submit" className="login__submit">
                        Send Code
                    </button>
                    {errors.form && (
                        <Information text={errors.form} setErrors={setErrors} />
                    )}
                </form>
                <div className="register__login">
                    Remembered your password?
                    <Link href="/login">Log in</Link>
                </div>
            </div>
        </>
    );
};
const ResetPasswordValidate = ({ token }) => {
    const initialFormValues = {
        password: "",
        confirmPassword: "",
    };
    const [formValues, setFormValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [location, navigate] = useLocation();
    const user = useLogin((state) => state.user);
    const loggedIn = useLogin((state) => state.loggedIn);
    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user]);

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErros = {};
        if (formValues.password.length < 8) {
            newErros.password = "password has to be more than 7 characters";
            setErrors(newErros);
            return false;
        }
        if (formValues.confirmPassword != formValues.password) {
            newErros.password = "password dismatches";
            setErrors(newErros);
            return false;
        }
        setErrors(newErros);
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Handle form submission logic here
            const data = new FormData();
            data.append("resetToken", token);
            data.append("newPassword", formValues.password);
            const config = {
                method: "post",
                url: `${proxy}/auth/reset-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then((response) => {
                    const newErrors = {};
                    newErrors.password = response.data.message;
                    console.log(response);
                    setErrors(newErrors);
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                })
                .catch((error) => {
                    const newErrors = {};
                    newErrors.password = error.response.data.message;
                    setErrors(newErrors);
                });
            setFormValues(initialFormValues);
        }

        // Reset form values to initial state
    };

    return (
        <>
            <div className="login__form">
                <form onSubmit={handleSubmit}>
                    <div className="login__inputs">
                        <input
                            type="password"
                            name="password"
                            placeholder="New Password"
                            value={formValues.password}
                            onChange={handleChange}
                            className="login__input"
                        />
                    </div>
                    <div className="login__inputs">
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={formValues.confirmPassword}
                            onChange={handleChange}
                            className="login__input"
                        />
                    </div>
                    {errors.password && (
                        <Information
                            text={errors.password}
                            setErrors={setErrors}
                        />
                    )}

                    <button type="submit" className="login__submit">
                        Reset Password
                    </button>
                    {errors.form && (
                        <Information text={errors.form} setErrors={setErrors} />
                    )}
                </form>
                <div className="register__login">
                    Want to Log in?
                    <Link href="/login">Log in</Link>
                </div>
            </div>
        </>
    );
};

const imageVariants = {
    hidden: {
        opacity: 0,
        x: 1000,
        transition: {
            type: "Tween",
        },
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            type: "Tween",
        },
    },
    out: {
        opacity: 0,
        x: -1000,
        transition: {
            type: "Tween",
        },
    },
};

const Slideshow = ({ image }) => {
    return (
        <AnimatePresence initial={false} mode="popLayout">
            <motion.img
                key={image}
                src={image}
                variants={imageVariants}
                initial="hidden"
                animate="visible"
                exit="out"
                className="login__slider--image"
            />
        </AnimatePresence>
    );
};

export {
    RegisterForm,
    LoginForm,
    Slideshow,
    VerifyForm,
    ResetPasswordValidate,
    ResetPasswordRequest,
};
