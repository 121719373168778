/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Cookies from "js-cookie";
import { useRef, useEffect, useState } from "react";
import { useLocation } from "wouter";

import Loader from "./Components/Loader/Loader";
import AFK from "./Components/AFK/AFK";
import ButtonMap from "./Components/ButtonMap/ButtonMap";

import { WebRTCClient, events } from "@arcware/webrtc-plugin";
import "./App.css";

const API_URL = "https://api.gammacities.com/api/users/profile";
const SIGNALING_URL = "wss://signalling-client.ragnarok.arcware.cloud/";

const getUserProfile = async () => {
    const token = Cookies.get("token");
    if (!token) {
        console.log("Token not found!");
        return null;
    }
    try {
        const response = await axios.get(API_URL, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error(
            "Failed to fetch user profile:",
            error.response ? error.response.data : error.message
        );
        return null;
    }
};

function Arcware() {
    // Refs
    const [videoContainerRef, sizeContainerRef, videoRef, audioRef] = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];
    const webrtcClient = useRef(null);

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [isAFK, setIsAFK] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [showInactiveWarning, setShowInactiveWarning] = useState(false);
    const [isSessionEnded, setIsSessionEnded] = useState(false);

    const [location] = useLocation();
    localStorage.removeItem("gamma-reference");

    // Handlers
    const handleFullScreenToggle = async () => {
        // TODO: use size container instead of video container
        if (!isFullScreen) {
            if (videoContainerRef.current.requestFullscreen) {
                videoContainerRef.current.requestFullscreen();
            } else if (videoContainerRef.current.webkitRequestFullscreen) {
                /* Safari */
                videoContainerRef.current.webkitRequestFullscreen();
            } else if (videoContainerRef.current.msRequestFullscreen) {
                /* IE11 */
                videoContainerRef.current.msRequestFullscreen();
            }
            setIsFullScreen(true);
            // document.documentElement.setAttribute('fullscreen', 'true');
        } else {
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen();
            }
            setIsFullScreen(false);
            // document.documentElement.removeAttribute('fullscreen');
        }
    };

    const handleMuteToggle = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };
    // Handlers for sending user_info event
    let isSending = false;

    const videoInitialized = () => {
        if (webrtcClient.current) {
            let attemptCount = 0;
            const maxAttempts = 5;

            // Get user profile
            let user = null;
            const fetchUserProfile = async () => {
                const profile = await getUserProfile();
                if (profile) {
                    user = profile;
                }
            };

            fetchUserProfile();

            let intervalId = setInterval(function () {
                if (attemptCount >= maxAttempts || isSending) {
                    clearInterval(intervalId);
                }

                // Send user info event
                if (user) {
                    const input = {
                        event: "joined_player",
                        data: {
                            pid: user._id,
                            name: user.username,
                        },
                    };
                    webrtcClient.current.emitUIInteraction(input);
                    console.log("emitting joined_player", input);
                }
                attemptCount++;
            }, 2000);
        }
    };

    const responseCallback = (message) => {
        console.log("message responseCallback", message);
        const data = JSON.parse(message);
        console.log("message responseCallback parse", data);
        if (data.success) {
            isSending = true;
        }
    };

    useEffect(() => {
        // Return if session ended
        if (isSessionEnded) return;

        let shareId;
        switch (location.split("/")[1]) {
            case "Galata":
                shareId = "share-66cde3bf-3257-44f5-963d-fb6a972b151a";
                break;
            case "Kaaba":
                shareId = "share-66cde3bf-3257-44f5-963d-fb6a972b151a";
                break;
            case "Villa":
                shareId = "share-9fb00439-2e73-424d-8212-4b2490f46795";
                break;
            case "Tinyhouse":
                shareId = "share-082d12d9-b5cc-471a-ac0c-6740e6b8e656";
                break;
            case "Flat":
                shareId = "share-49b97152-36bf-46d2-9e19-9a2ed916ea8e";
                break;
            case "Office":
                shareId = "share-874e29fd-2739-48bd-ad6e-97d75bd66d66";
                break;
            default:
                console.log(`Wrong path`);
        }

        const args = {
            address: SIGNALING_URL,
            shareId,
            settings: {},
            playOverlay: false,
            loader: setIsLoading,
            sizeContainer: sizeContainerRef.current,
            container: videoContainerRef.current,
            videoRef: videoRef.current,
            audioRef: audioRef.current,
            applicationResponse: responseCallback,
            videoInitializeCallback: videoInitialized,
        };

        // double load protection
        if (!webrtcClient.current) {
            webrtcClient.current = new WebRTCClient(args);

            // Event listeners
            // Seconds of inactivity until warning event is triggered.
            webrtcClient.current.events.addListener(
                events.EventTypes.AFK_Warn,
                () => {
                    setShowInactiveWarning(true);
                }
            );

            // Seconds of inactivity after the warning, until error event is triggered.
            webrtcClient.current.events.addListener(
                events.EventTypes.AFK_Error,
                () => {
                    setShowInactiveWarning(false);
                }
            );

            // Seconds of inactivity after the error, until the session will be ended.
            webrtcClient.current.events.addListener(
                events.EventTypes.AFK_Action,
                // Last envent will call once the user is marked AFK
                () => {
                    setShowInactiveWarning(false);
                    // Remove listeners
                    webrtcClient.current.events.removeListener(
                        events.EventTypes.AFK_Warn
                    );
                    webrtcClient.current.events.removeListener(
                        events.EventTypes.AFK_Error
                    );
                    webrtcClient.current.events.removeListener(
                        events.EventTypes.AFK_Action
                    );
                    webrtcClient.current.events.removeListener(
                        events.EventTypes.AFK_Abort
                    );
                    // End session
                    webrtcClient.current = null;
                    setIsSessionEnded(true);
                    setIsAFK(true);
                    setIsLoading(false);
                    // TODO: add exist fullscreen by checking isFullScreen state
                    // if (isFullScreen) {
                    //   handleFullScreenToggle();
                    // }
                }
            );

            // Event that is triggered when the user interacts with the application.
            webrtcClient.current.events.addListener(
                events.EventTypes.AFK_Abort,
                () => {
                    setShowInactiveWarning(false);
                }
            );
        }
    }, [isSessionEnded]);

    // TODO: fix other pages and warning not showing up on fullscreen
    return (
        <div className="Streamer-App" style={{ width: "99.99%" }}>
            {showInactiveWarning && (
                <div className="inactive-alert">
                    You are about to be marked AFK. Please interact with the
                    application.
                </div>
            )}
            {/* {showInactiveError && (
        <div className="inactive-alert">
                You have been marked AFK due to inactivity.
              </div>
      )} */}
            {isLoading && !isAFK && (
                <div className="is-loading-container">
                    <Loader />
                </div>
            )}
            {isAFK && (
                <div className="is-afk-container">
                    <AFK />
                </div>
            )}
            <div
                className="stream-container"
                style={{
                    visibility: isLoading ? "hidden" : "visible",
                    height: isLoading ? "0" : "100%",
                    width: isLoading ? "0" : "100%",
                }}
                ref={sizeContainerRef}
            >
                <div
                    ref={videoContainerRef}
                    style={{ zIndex: 1, width: "90%" }}
                >
                    <video ref={videoRef} />
                    <audio ref={audioRef} />
                    {/* <button
            className="mute-toggle-button"
            style={{ display: isLoading ? 'none' : 'block' }}
            onClick={handleMuteToggle}
          >
            Mute/Unmute
          </button> */}
                    {/* <button
            className="fullscreen-toggle-button"
            style={{ display: isLoading ? 'none' : 'block' }}
            onClick={handleFullScreenToggle}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button> */}
                    {/* Onclick tab button open Buttons Modal */}
                    <ButtonMap
                        handleMuteToggle={handleMuteToggle}
                        handleFullScreenToggle={handleFullScreenToggle}
                    />
                </div>
            </div>
        </div>
    );
}

export default Arcware;
