import { Html, useTexture } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { gsap, ScrollTrigger } from "../../../Scripts/gsap.js";
import {
    motion,
    useDragControls,
    useMotionValue,
    useTransform,
} from "framer-motion";
import axios from "axios";

import vertexShader from "./shaders/earth/vertex.glsl";
import fragmentShader from "./shaders/earth/fragment.glsl";
import { useExploreStore } from "./Planets.jsx";
import { useLocation } from "wouter";
import { useLogin } from "../../../Auth/Login.jsx";

export default function Earth({ lenis, isMobile }) {
    const textureMap = useTexture("/textures/earthDay.jpg");
    const earth = useRef();
    const explorer = useRef();

    const { camera, viewport } = useThree();

    const { _, height } = viewport.getCurrentViewport(camera, [0, 0, -10]);
    const buttonsPositions = viewport.getCurrentViewport(camera, [0, 0, 0]);
    const viewHeight = isMobile
        ? (height / 2) * 0.55
        : height / 2 - (height / 2) * 0.65;

    const material = useMemo(() => {
        return new THREE.ShaderMaterial({
            transparent: true,
            uniforms: {
                uDay: { value: textureMap },
                uOpacity: { value: 1 },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        });
    }, []);

    const planets = document.getElementById("planets");

    useLayoutEffect(() => {
        const dummy = document.getElementById("Home-section");
        const planets = document.getElementById("planets");
        const about = document.getElementsByClassName("about");

        ScrollTrigger.create({
            id: "z-index",
            trigger: dummy,
            start: "bottom bottom",
            end: "bottom bottom",
            onEnter: () => {
                planets.classList.add("planets__z");
            },

            onEnterBack: () => {
                planets.classList.remove("planets__z");
            },
        });

        /**
         * Scale it up while scrolling
         */
        const scale = 1.5;
        gsap.to(earth.current.scale, {
            x: scale,
            y: scale,
            z: scale,
            scrollTrigger: {
                trigger: dummy,
                scrub: 1,
                start: "top top",
                end: "75% bottom",
            },
        });

        /**
         * Opacity of button explorer
         */
        ScrollTrigger.create({
            id: "explorer-earth",
            trigger: dummy,
            start: isMobile ? "50% 65%" : "45% center",
            end: isMobile ? "50% 65%" : "45% center",
            onEnter: () => {
                gsap.to(explorer.current, {
                    opacity: 0,
                    duration: 0.3,
                });
                gsap.to(explorer.current, {
                    display: "none",
                    delay: 0.3,
                    duration: 0,
                });
            },
            onEnterBack: () => {
                gsap.to(explorer.current, {
                    display: "block",
                    duration: 0,
                });
                gsap.to(explorer.current, {
                    opacity: 1,
                    duration: 0.3,
                    delay: 0.1,
                });
            },
        });

        /**
         * Move it to the right while scrolling
         */
        if (!isMobile) {
            gsap.to(earth.current.position, {
                x: 5.5,
                z: -10,
                scrollTrigger: {
                    trigger: dummy,
                    scrub: 1,
                    // start: '25% center',
                    start: "50% center",
                    end: "100% center",
                },
            });
        }

        /**
         * If we are in about us section
         */
        if (!isMobile) {
            ScrollTrigger.create({
                id: "about-section",
                trigger: about,
                start: "top top",
                end: "top center",
                onEnter: () => {
                    const elDistanceToTop =
                        window.pageYOffset +
                        planets.getBoundingClientRect().top;
                    planets.style.top = `${elDistanceToTop}px`;
                    planets.classList.add("remove-fixed");
                },
                onEnterBack: () => {
                    planets.classList.remove("remove-fixed");
                    planets.style.top = "";
                },
            });
        } else {
            setTimeout(() => {
                ScrollTrigger.create({
                    id: "about-section",
                    trigger: about,
                    start: "top bottom",
                    end: "top bottom",
                    onEnter: () => {
                        const elDistanceToTop =
                            window.pageYOffset +
                            planets.getBoundingClientRect().top;
                        planets.style.top = `${elDistanceToTop}px`;
                        planets.classList.add("remove-fixed");
                    },
                    onEnterBack: () => {
                        planets.classList.remove("remove-fixed");
                        planets.style.top = "";
                    },
                });
            }, 1000);
        }
    }, []);

    useEffect(() => {
        ScrollTrigger.refresh();
    }, [isMobile]);

    useFrame((state, delta) => {
        earth.current.rotation.y += -delta * 0.225;
        /**
         * Fixing the planet position if we scroll fast
         */
        let getOffsetTop = function (elem) {
            // Set our distance placeholder
            let distance = 0;

            // Loop up the DOM
            if (elem?.offsetParent) {
                do {
                    distance += elem.offsetTop;
                    elem = elem.offsetParent;
                } while (elem);
            }

            // Return our distance
            return distance < 0 ? 0 : distance;
        };

        let elem = document.querySelector(".about");
        let offsetY = getOffsetTop(elem);
        if (!isMobile && window.scrollY > offsetY) {
            planets.style.position = "absolute";
            planets.style.top = `${offsetY + 6}px`;
            explorer.current.style.opacity = 0;
        } else if (!isMobile) {
            planets.style.position = "fixed";
            planets.style.top = `0`;
        }
        if (isMobile) {
            if (planets.style.top.replace("px", "") > offsetY - 800) {
                // planets.style.top = `${offsetY - 800}px`;
            }
        } else {
            if (planets.style.top.replace("px", "") > offsetY + 6) {
                planets.style.top = `${offsetY + 6}px`;
            }
        }
    });
    const widthSegments = 55;
    const heightSegments = 35;

    const changeExplore = useExploreStore((state) => state.changeExplore);
    const Explore = useExploreStore((state) => state.explore);
    const isIntrested = useExploreStore((state) => state.isIntrested);
    const user = useLogin((state) => state.user);

    const dragContainer = useRef(null);
    const dragControls = useDragControls();
    function startDrag(event) {
        dragControls.start(event, { snapToCursor: true });
    }
    const widthOfProgressBar = 336;
    const widthOfActualSpot = 8;
    const maxPerncetage = widthOfProgressBar - widthOfActualSpot;
    const x = useMotionValue(0);
    const percentage = useTransform(x, [0, maxPerncetage], ["0%", "100%"]);
    useEffect(() => {}, [percentage]);

    const [location, setLocation] = useLocation();

    const [amoutIntrested, setAmoutIntrested] = useState(10);

    const handleAmoutChange = (e) => {
        setAmoutIntrested(e.target.value);
    };

    const remap = (amoutIntrested) => {
        let result = 100;
        if (amoutIntrested < 25) {
            const actualPercentage =
                gsap.utils.mapRange(0, 25, 0, 100, amoutIntrested) / 100;
            const actualNumber = gsap.utils.interpolate(
                100,
                200,
                actualPercentage
            );
            result = actualNumber;
        } else if (amoutIntrested < 50) {
            const actualPercentage =
                gsap.utils.mapRange(25, 50, 0, 100, amoutIntrested) / 100;
            const actualNumber = gsap.utils.interpolate(
                200,
                500,
                actualPercentage
            );
            result = actualNumber;
        } else if (amoutIntrested < 75) {
            const actualPercentage =
                gsap.utils.mapRange(50, 75, 0, 100, amoutIntrested) / 100;
            const actualNumber = gsap.utils.interpolate(
                500,
                1000,
                actualPercentage
            );
            result = actualNumber;
        } else if (amoutIntrested < 100) {
            const actualPercentage =
                gsap.utils.mapRange(75, 100, 0, 100, amoutIntrested) / 100;
            const actualNumber = gsap.utils.interpolate(
                1000,
                2000,
                actualPercentage
            );
            result = actualNumber;
        } else {
            result = 2000;
        }
        return result;
    };

    const sendAmountToBackEnd = () => {
        const proxy = "https://api.gammacities.com/api";

        const data = new FormData();
        const actualAmountIntrested = remap(amoutIntrested);
        data.append("intrested", actualAmountIntrested);
        const config = {
            method: "put",
            url: `${proxy}/users/intrested`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
            },
            data: data,
        };
        axios(config)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <>
            <mesh
                ref={earth}
                position={[0 - 0.25, viewHeight, -10]}
                material={material}
            >
                <sphereGeometry
                    args={
                        isMobile
                            ? [1.5, widthSegments, heightSegments]
                            : [2.5, widthSegments, heightSegments]
                    }
                />
            </mesh>
            <Html
                style={
                    isMobile
                        ? {
                              position: "absolute",
                              top: "-25vh",
                              left: "50%",
                              transform: "translateX(-50%)",
                          }
                        : { position: "absolute", top: "-40vh", left: "25vw" }
                }
            >
                <button
                    ref={explorer}
                    onClick={() => {
                        // changeExplore();
                        window.open(
                            "https://platform.gammaassets.com",
                            "_blank"
                        );
                    }}
                    className="planets__button"
                >
                    Gamma Assets
                    <span className="planets__span">Join our Platform</span>
                </button>
                {Explore && (
                    <div
                        className="planets__intrested"
                        style={{
                            translate: isMobile ? "0 -130%" : "",
                        }}
                    >
                        <span className="planets__intrested--text">
                            I'm Interested to buy
                        </span>
                        <h1 className="planets__intrested--title">
                            GMAC Token
                        </h1>
                        <motion.div
                            ref={dragContainer}
                            className="planets__intrested--progressBar"
                            onPointerDown={startDrag}
                        >
                            <input
                                className="planets__intrested--range"
                                type="range"
                                min={0}
                                max={100}
                                value={amoutIntrested}
                                onChange={handleAmoutChange}
                            />
                            <div className="planets__intrested--checkPoint">
                                <span>$100</span>
                            </div>
                            <div className="planets__intrested--checkPoint">
                                <span>$200</span>
                            </div>
                            <div className="planets__intrested--checkPoint">
                                <span>$500</span>
                            </div>
                            <div className="planets__intrested--checkPoint">
                                <span>$1000</span>
                            </div>
                            <div className="planets__intrested--checkPoint">
                                <span>+$1000</span>
                            </div>
                        </motion.div>

                        <button
                            onClick={() => {
                                if (user) {
                                    isIntrested();
                                    sendAmountToBackEnd();
                                } else {
                                    setLocation("/register?intrested=true");
                                }
                            }}
                            className="planets__intrested--button"
                        >
                            Continue
                        </button>
                    </div>
                )}
            </Html>
        </>
    );
}
