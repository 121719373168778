varying vec2 vUv;
#define PI 3.1415926535897932384626433832795

void main()
{
    float strength = distance(gl_PointCoord, vec2(0.5));
    strength *= 2.0;
    strength = 1.0 - strength;

    gl_FragColor = vec4(vec3(strength), 1.0) * strength * 0.7;
    
    
}