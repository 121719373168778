import { useEffect, useLayoutEffect } from "react";
import { Route, Switch } from "wouter";

import Home from "./Home.jsx";
import Login from "./Auth/Login.jsx";
import Register from "./Auth/Register.jsx";
import Arcware from "./Arcware/Index.jsx";
import Linktree from "./Linktree/Linktree.jsx";
import Verify from "./Auth/Verify.jsx";
import ResetPassword from "./Auth/ResetPassword.jsx";

import Disclosure from "./Terms/Disclosure.jsx";
import Consent from "./Terms/Consent.jsx";
// import Gmac from "./Gmac/crypto-token-index.jsx";

export default function App() {
    useLayoutEffect(() => {
        // if (location.pathname === "/gmac") {
        //     console.log("we are in");
        //     import("./Gmac/assets/css/bootstrap.min.css");
        //     import("./Gmac/assets/css/slick.css");
        //     import("./Gmac/assets/css/slick-theme.css");
        //     import("./Gmac/assets/css/asPieProgress.min.css");
        //     import("./Gmac/assets/css/venobox.min.css");
        //     import("./Gmac/assets/css/lightbox.css");
        //     import("./Gmac/assets/css/headline.css");
        //     import("./Gmac/assets/css/all.min.css");
        //     import("./Gmac/assets/css/style.css");
        //     import("./Gmac/assets/css/responsive.css");
        // } else {
        //     console.log("we not");
        // }
        return () => {
            localStorage.removeItem("loaded");
        };
    }, []);

    return (
        <>
            <Switch>
                <Route path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/verify" component={Verify} />
                <Route path="/reset" component={ResetPassword} />
                <Route path="/linktree" component={Linktree} />
                <Route path="/disclosure" component={Disclosure} />
                <Route path="/consent" component={Consent} />
                <Route path="/Villa" component={Arcware} />
                <Route path="/Tinyhouse" component={Arcware} />
                <Route path="/Office" component={Arcware} />
                <Route path="/Flat" component={Arcware} />
                <Route path="/Galata" component={Arcware} />
                <Route path="/Kaaba" component={Arcware} />
            </Switch>
        </>
    );
}
