import '../Styles/background.css'


export default function Background() {


    return <>
        <div className="background">
            <div className="background__grid"></div>
            <div className="background__grid"></div>
            <div className="background__grid"></div>
            <div className="background__grid"></div>
        </div>
    </>
}