uniform float uSize;
uniform float uTime;

attribute float aScale;
attribute float aSign;

void main()
{   
    /**
             * Position
             */
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);
            modelPosition.x += sin(uTime  * aSign * aScale) * aSign * aScale * 0.125;
            modelPosition.y += cos(uTime  * aSign * aScale) * aSign * aScale * 0.125;
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectedPosition = projectionMatrix * viewPosition;
            gl_Position = projectedPosition;

            /**
             * Size
             */
            gl_PointSize = uSize * aScale;
            gl_PointSize *= (1.0 / - viewPosition.z);
}