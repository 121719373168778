import '../Styles/Terms/disclosure.css'


export default function Consent() {


    return <>
        <div className="disclosure">
            <h1 className="disclosure__title">
                EXPLICIT CONSENT TO PROCESSING OF PERSONAL DATA
            </h1>
            <p className="disclosure__text">
                I understand the issues subject to notification and that I have learned in detail my rights regarding the processing of my personal data by TDA Yapi Emlak Organizasyon ve Ticaret Ltd Şti (Gamma Cities) through the Disclosure Text On The Protection Of Personal Data.
            </p>
            <p className="disclosure__text">
                I consent to processing of my personal data for the purposes of developing and customizing our services on the Website and mobile applications of Gamma Cities Metaverse according to user needs, and conducting marketing and promotional activities campaigns, advantages, promotions, creation and transmission of advertisements, using the Gamma Cites Application and website  providing the Services based on the Agreement.
            </p>
            <p className="disclosure__text">
                I consent to the transfer of my personal data to the Company’s suppliers, business partners, subsidiaries and shareholders located domestically for the purposes of developing the Application and various services within the Application and personalizing them according to User needs, marketing, conducting promotional activities, creating and transmitting campaigns, advantages, promotions, advertisements.
            </p>

        </div>
    </>
}