import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { gsap, Power4 } from "gsap";

import { Text, Html, useTexture } from "@react-three/drei";
import { Suspense } from "react";

const roadmapTexts = [
    {
        Q: "Q2 2023",
        title: "WhitePaper",
        description:
            "Introducing Hot Zones - special locations on Gamma city where real businesses can show- case their offerings, like 3D showrooms and digital apartments. Each Hot Zone is centered around a stunning monument and is an exact digital replica of the real-life location. First locations in Istanbul, Dubai, and more cities",
    },
    {
        // Q: "Q3 2023",
        // title: "Gamma Spaces",
        // description:
        //     "To enrich Gamma cities, we're thrilled to offer our service - Metaverse Showcasing! This cutting-edge service allows businesses to utilize a stunning 3D environment that's both user-friendly and easy to access. With Metaverse Showcasing, businesses with intri- cate and products can showcase them in a more interactive and clear manner to a wider",
        // spot: "Beta",
        title: "",
    },
    {
        Q: "Q1 2024",
        title: "GMAC TOKEN",
        description:
            "Publish the sustainable economical system for Gamma Cities, which will involve fair me- chanics pricing and benefits for digital and real properties, and diverse revenue streams. Our commitment to these principles will ensure a thriving and attractive ecosystem and governance in the Metaverse.",
        spot: "Presale - ICO",
        picture: "/textures/gamma-coin.png",
    },
    {
        title: "GAMMA Fractions",
        description:
            "an exciting addition to Gamma Cities that will take entertainment to a whole new level! Our Metaverse ecosystem will be enhanced with a variety of fun activities, including watching cultural ceremonies, participating in treasure hunt games, racing over the bridge, and even witnessing wormhole portals swallowing towers!",
        spot: "Beta",
    },
    {
        Q: "Q1 2024",
        title: "GAMMA Fractions",
        description:
            "Allow Whitelisted partners and early birds to redeem their tokens",
        spot: "Launch",
    },
    {
        title: "",
    },
    {
        title: "",
        // title: "GAMMA Spaces",
        // description:
        //     "Offer in-world buying and selling options for users within the Gamma Cities Metaverse. Whether it's digital items or those with a con- nection to the real world, our platform will allow for seamless transactions between buyers and sellers. This feature will also bene- fit businesses by enabling them to offer trade",
        //     spot: "Launch",
        // },
    },
    {
        title: "GAMMA verse",
        description:
            "Offer in-world buying and selling options for users within the Gamma Cities Metaverse. Whether it's digital items or those with a con- nection to the real world, our platform will allow for seamless transactions between buyers and sellers. This feature will also bene- fit businesses by enabling them to offer trade",
        spot: "Private Hot Zones",
    },
    {
        Q: "Q4 2024",
        title: "GAMMA verse",
        description:
            "Experience the ultimate in digital living with Gamma Cities Metaverse, access to your very own digital apartment that you can furnish to your liking. Take a stroll around the neighbor- hood, participate in exciting adventures, and even benefit from the Hot Zone income pool",
        spot: "Public Hot Zones",
    },
];

export default function ShapesText({ coords }) {
    const isMobile = window.matchMedia("(max-width: 930px)").matches;

    let isVisible = false;

    const refs = useRef(new Array());
    const divs = useRef(new Array());

    const intro = useRef();
    const outro = useRef();

    const outroPosition = [1, 0.2, -4.4];

    const map = useTexture("/textures/gamma-coin.png");

    useFrame((state) => {
        if (refs.current.length) {
            refs.current.map((item, key) => {
                if (
                    item != null &&
                    item.position.distanceTo(state.camera.position) > 0.5 &&
                    state.camera.position.z >= item.position.z
                )
                    item.lookAt(state.camera.position);
            });
            intro.current.lookAt(new THREE.Vector3(0, 0.7, 10));
            outro.current.lookAt(new THREE.Vector3(1, 0.7, -4));
        }

        if (
            refs.current.length == coords.length &&
            divs.current.length == coords.length
        ) {
            refs.current.map((point, key) => {
                if (
                    point.position?.distanceTo(state.camera.position) < 1.5 &&
                    roadmapTexts[key].description
                ) {
                    gsap.to(divs.current[key], {
                        opacity: 1,
                        duration: 0.1,
                    });
                } else {
                    gsap.to(divs.current[key], {
                        opacity: 0,
                        duration: 0.25,
                    });
                }
            });

            if (
                outro.current.position.distanceTo(state.camera.position) < 1 &&
                !gsap.isTweening(outro.current) &&
                outro.current.position.y != 0.8
            ) {
                gsap.to(outro.current.position, {
                    y: 0.8,
                    duration: 1.5,
                    ease: Power4.easeOut,
                });
                gsap.to(outro.current.material, {
                    opacity: 1,
                    duration: 1,
                });
                gsap.to(outro.current.children[0].material, {
                    opacity: 1,
                    duration: 1,
                });
                gsap.to(outro.current.children[1].material, {
                    opacity: 1,
                    duration: 1,
                });
                isVisible = true;
            } else if (isVisible) {
                gsap.to(outro.current.position, {
                    y: 0.2,
                    duration: 1.5,
                });
                gsap.to(outro.current.material, {
                    opacity: 0,
                    duration: 1,
                });
                gsap.to(outro.current.children[0].material, {
                    opacity: 0,
                    duration: 1,
                });
                gsap.to(outro.current.children[1].material, {
                    opacity: 0,
                    duration: 1,
                });
                isVisible = false;
            }
        }
    });

    return (
        <>
            <Suspense fallback={null}>
                {coords.map((coord, i) => {
                    return Object.prototype.hasOwnProperty.call(
                        roadmapTexts[i],
                        "description"
                    ) ? (
                        <Text
                            key={i}
                            ref={(element) => {
                                if (refs.current.length < 10)
                                    refs.current.push(element);
                            }}
                            position={[
                                coord.x,
                                roadmapTexts[i].spot
                                    ? coord.y - 0.05
                                    : coord.y - 0.1,
                                coord.z,
                            ]}
                            color={"#00FFFF"}
                            fontSize={isMobile ? 0.015 : 0.05}
                            maxWidth={0.8}
                            lineHeight={1}
                            letterSpacing={0.1}
                            textAlign={"right"}
                            font="/fonts/Montserrat.woff"
                            anchorX="center"
                            anchorY="bottom"
                            outlineOffsetX={"10%"}
                            outlineOffsetY={"10%"}
                            outlineBlur={"30%"}
                            outlineOpacity={0.3}
                            outlineColor="#00FFFF"
                        >
                            {roadmapTexts[i].title}
                            {roadmapTexts[i].spot && (
                                <Text
                                    position={[0, -0.02, 0]}
                                    color={"#FFFFFF"}
                                    fontSize={0.03}
                                    maxWidth={0.8}
                                    lineHeight={1}
                                    letterSpacing={0.1}
                                    textAlign={"right"}
                                    font="/fonts/Montserrat.woff"
                                >
                                    {roadmapTexts[i].spot}
                                </Text>
                            )}
                            {roadmapTexts[i].Q && (
                                <Text
                                    position={[0, 0.11, 0]}
                                    color={"#FFFFFF"}
                                    fontSize={0.05}
                                    maxWidth={0.8}
                                    lineHeight={1}
                                    letterSpacing={0.1}
                                    textAlign={"right"}
                                    font="/fonts/Montserrat.woff"
                                >
                                    {roadmapTexts[i].Q}
                                </Text>
                            )}
                            {roadmapTexts[i].picture && (
                                <mesh position={[0, 0.2, 0]}>
                                    <planeGeometry args={[0.1, 0.1]} />
                                    <meshBasicMaterial
                                        map={map}
                                        transparent
                                        toneMapped={false}
                                    />
                                </mesh>
                            )}

                            <Html
                                ref={(element) => divs.current.push(element)}
                                position-y={isMobile ? 0.15 : 0.3}
                                wrapperclassName="roadmap__box"
                                distanceFactor={isMobile ? 0.5 : 5}
                                center
                                style={{
                                    display: "none",
                                }}
                            >
                                {roadmapTexts[i].description &&
                                    roadmapTexts[i].description}
                            </Html>
                        </Text>
                    ) : (
                        <Text
                            key={i}
                            position={[coord.x, 0.8, coord.z]}
                            ref={(element) => {
                                if (refs.current.length < 10)
                                    refs.current.push(element);
                            }}
                            color={"#FFFFFF"}
                            fontSize={isMobile ? 0.02 : 0.04}
                            maxWidth={0.8}
                            lineHeight={1}
                            letterSpacing={0.1}
                            textAlign={"center"}
                            font="/fonts/Montserrat.woff"
                            anchorX="center"
                            anchorY="bottom"
                            outlineWidth={0.002}
                            outlineColor="#FFFFFF"
                        >
                            {roadmapTexts[i].title}

                            <Html
                                ref={(element) => divs.current.push(element)}
                                position-y={0.3}
                                wrapperclassName="roadmap__box"
                                // distanceFactor={isMobile ? 1 : 5}
                                distanceFactor={isMobile ? 1 : 5}
                                center
                                style={{
                                    display: "none",
                                }}
                            >
                                {roadmapTexts[i].description &&
                                    roadmapTexts[i].description}
                            </Html>
                        </Text>
                    );
                })}
                {/* INTRO */}
                <Text
                    ref={intro}
                    position={[-0.125, 0.3, 9.6]}
                    color={"#00FFFF"}
                    fontSize={isMobile ? 0.02 : 0.04}
                    maxWidth={0.8}
                    lineHeight={1}
                    letterSpacing={0.1}
                    textAlign={"center"}
                    font="/fonts/Montserrat.woff"
                    anchorX="center"
                    anchorY="bottom"
                    outlineWidth={0.002}
                    outlineColor="#00FFFF"
                >
                    Inception
                    <Text
                        position={[0, 0.25, 0]}
                        color={"#FFFFFF"}
                        fontSize={0.05}
                        maxWidth={0.8}
                        lineHeight={1}
                        letterSpacing={0.1}
                        textAlign={"right"}
                        font="/fonts/Montserrat.woff"
                    >
                        <meshBasicMaterial transparent opacity={0} />
                        Q2 2022
                    </Text>
                </Text>
                {/* Outro */}
                <Text
                    ref={outro}
                    position={[...outroPosition]}
                    color={"#00FFFF"}
                    fontSize={isMobile ? 0.02 : 0.05}
                    maxWidth={0.8}
                    lineHeight={1}
                    letterSpacing={0.1}
                    textAlign={"center"}
                    font="/fonts/Montserrat.woff"
                    anchorX="center"
                    anchorY="bottom"
                    outlineWidth={0.002}
                    outlineColor="#00FFFF"
                >
                    <meshBasicMaterial transparent opacity={0} />
                    GAMMA Verse
                    <Text
                        position={[0, -0.02, 0]}
                        color={"#FFFFFF"}
                        fontSize={0.03}
                        maxWidth={0.8}
                        lineHeight={1}
                        letterSpacing={0.1}
                        textAlign={"right"}
                        font="/fonts/Montserrat.woff"
                    >
                        <meshBasicMaterial transparent opacity={0} />
                        Open World Map
                    </Text>
                    <Text
                        position={[0, 0.11, 0]}
                        color={"#FFFFFF"}
                        fontSize={0.05}
                        maxWidth={0.8}
                        lineHeight={1}
                        letterSpacing={0.1}
                        textAlign={"right"}
                        font="/fonts/Montserrat.woff"
                    >
                        <meshBasicMaterial transparent opacity={0} />
                        Q2 2025
                    </Text>
                </Text>
            </Suspense>
        </>
    );
}
