import React from 'react';
import './AFK.css';
import logoImage from './Logo.png';
import leftArrowImage from './Left-arrow.png';

const AFK = () => {
  const handleButtonClick = () => {
    window.location.reload(); // Refresh the page
  };

  return (
    <>
      <section className='afk-container'>
       <section className="afk-section1">
        <div className="content1">
          <h2 className="afk-header">AFK</h2>
          <div className="loaders">
            <span className="loader-arcware"></span>
            <span className="loader-arcware"></span>
          </div>
          <h1 className="resting-text">YOUR AVATAR IS RESTING FOR A WHILE</h1>
        </div>
        <img
          onClick={handleButtonClick}
          src={leftArrowImage}
          alt="Left Arrow"
          className="left-arrow"
        />
      </section>
      <section className="section2">
        <div className="content-text">
          <h2>while you were AFK</h2>
          <p>the world kept spinning</p>
          {/* <button className="spaces-button">Another spaces</button> */}
          <button className="reconnect-button" onClick={handleButtonClick}>
            Reconnect
          </button>
        </div>
        <div className="content2">
          <img src={logoImage} alt="Logo" className="logo" />
        </div>
      </section>
      </section>
      </>
  );
};

export default AFK;
