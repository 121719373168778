import { motion } from "framer-motion";
import Links from "./Links.jsx";

export default function Home({ mouse }) {

    const intensity = 50

    const containerVariants = {
        visible: {
            rotateX: -mouse.y * intensity,
            rotateY: mouse.x * intensity,
            transition: {
                type: 'Inertia',
            }
        }
    }

    const imageVariants = {
        hidden: {
            scale: 0,
        },
        visible: {
            scale: 1,
            transition: {
                type: 'spring',
            }
        }
    }

    return <>
        <section className="home">

            <div className="home__hero">
                <Links />

                <div className="home__hero__figure">
                    <motion.div
                        variants={containerVariants}

                        className="home__hero__figure--wrapper"
                    >



                        <motion.img
                            variants={imageVariants}
                            initial="hidden"
                            animate="visible"
                            src="/Linktree/figure/earth.png"
                            alt=""
                            className="home__hero__figure--image home__hero__figure--earth"

                        />

                    </motion.div>
                </div>
            </div>
        </section>

    </>
}