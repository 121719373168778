import React, { useEffect, useState } from 'react';
import './ButtonMap.css';
import discovertext from './icons/discover-text.png';
import user from './icons/profile.png';
import chat from './icons/chat.png';
import exporticon from './icons/export.png';
import fullScreen from './icons/full-screen.png';
import about from './icons/about.png';
import microphone from './icons/microphone.png';
import record from './icons/record.png';
import volume from './icons/volume.png';
import aboutModal from './icons/about-modal.png';
import f2 from './icons/f2.png';
import aboutModalMobile from './icons/about-modal-mobile.png';
import spaces from './icons/spaces.jpg';
import leaveSpaces from './icons/leave-spaces.png';

const ButtonMap = ({ handleMuteToggle, handleFullScreenToggle }) => {
  const [isButtonModalOpen, setIsButtonModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // // Additional State
  // const [isButtonModalOpen, setIsButtonModalOpen] = useState(false);

  // const modalRef = useRef(null);

  // useEffect(() => {
  //   const handleTabKeyPress = (event) => {
  //     if (event.key === 'Tab') {
  //       event.preventDefault(); // Prevent default tab behavior
  //       setIsButtonModalOpen(!isButtonModalOpen);
  //     }
  //   };

  //   document.addEventListener('keydown', handleTabKeyPress);

  //   return () => {
  //     document.removeEventListener('keydown', handleTabKeyPress);
  //   };
  // }, [isButtonModalOpen]);

  return (
    <>
      {/* {isButtonModalOpen && ( */}
      <div className="button-modal">
        <div className="modal-content">
          <div className="top-left">
            <div className="send">
              <img src={exporticon} alt="" />
              <div
                style={{ marginTop: '20px', left: '0px' }}
                className="tooltip"
              >
                Share
              </div>
            </div>
            <div
              className="discover"
              onClick={() => setIsButtonModalOpen(!isButtonModalOpen)}
            >
              <img src={discovertext} alt="" />
              <div
                style={{ marginTop: '20px', left: '70px' }}
                className="tooltip"
              >
                discover more spaces
              </div>
            </div>
            <div onClick={handleFullScreenToggle} className="send">
              <img src={fullScreen} alt="" />
              <div
                style={{ marginTop: '20px', left: '155px' }}
                className="tooltip"
              >
                Full Screen
              </div>
            </div>
            {isButtonModalOpen && (
              <div className="button-modal2">
                <div className="leave-spaces">
                  <img src={leaveSpaces} alt="" />
                </div>
                <button
                  className="close-button2"
                  onClick={() => setIsButtonModalOpen(false)}
                >
                  &#x2716;
                </button>
                <img
                  src={spaces}
                  alt="Modal representation"
                  className="spaces-modal-image"
                />
              </div>
            )}
          </div>
          <div className="top-right">
            <div className="chat-mobile">
              <img src={chat} alt="" />
              <div
                style={{ marginTop: '20px', right: '217px' }}
                className="tooltip"
              >
                Chat
              </div>
            </div>
            <div className="volume" onClick={handleMuteToggle}>
              <img src={volume} alt="" />
              <div
                style={{ marginTop: '20px', right: '157px' }}
                className="tooltip"
              >
                Speaker
              </div>
            </div>
            <div className="video">
              <img src={record} alt="" />
              <div
                style={{ marginTop: '20px', right: '103px' }}
                className="tooltip"
              >
                Video
              </div>
            </div>
            <div className="microphone">
              <img src={microphone} alt="" />
              <div
                style={{ marginTop: '20px', right: '35px' }}
                className="tooltip"
              >
                Microphone
              </div>
            </div>
            <div className="user">
              <img src={user} alt="" />
              <div
                style={{ marginTop: '5px', right: '0px' }}
                className="tooltip"
              >
                Profile
              </div>
            </div>
          </div>

          <div className="bottom-left">
            <div className="f2">
              <img src={f2} alt="" />
              <div style={{ bottom: '60px', right: '5px' }} className="tooltip">
                Click for Open a cursor
              </div>
            </div>

            <div className="about-arcware">
              <div className="tooltip-image1">
                <img src={isMobile ? aboutModalMobile : aboutModal} alt="" />
              </div>
              <img src={about} alt="" />
            </div>
          </div>
          <div className="bottom-right">
            <div className="chat">
              <img src={chat} alt="" />
              <div style={{ bottom: '60px', right: '5px' }} className="tooltip">
                Chat
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ButtonMap;
