varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vNormalF;
varying vec3 vPosition;
#define PI 3.1415926535897932384626433832795

uniform sampler2D uDay;
uniform sampler2D uNight;
uniform float uOpacity;

void main()
{
    vec3 color = vec3(0.);
    vec3 bright = vec3(0.04, 0.49, 0.57);
    vec3 normal = normalize(vNormal);
    vec3 normalF = normalize(vNormalF);

    // float intensity = 1.05 - dot(vNormal, vec3(0.0, 0.0, 1.0));
    // vec3 atmosphere = vec3(0.3, 0.6, 1.0) *pow(intensity, 1.5);
    //Fresnel 
    vec3 viewDir = normalize(cameraPosition - vPosition);
    float fresnel = 1. - max(0., dot(viewDir, normalF));
    fresnel = pow(fresnel, 4.);
    vec3 fresnelColor = mix(vec3(0.), bright, fresnel);
    
    vec3 day = texture2D(uDay, vUv).xyz;
    vec3 night = texture2D(uNight, vUv).xyz;

    float t = cos((vUv.y - vUv.x) *5.);
    t=normal.y + normal.x - normal.y/3.;
    // t= abs(normal.y) + abs(normal.x);
    // t= normal.z + abs(normal.x);
    t= smoothstep(0., .9, t);

    // color = mix(night, day, t);
    color = day;
    color =  color + bright * fresnel;

    gl_FragColor = vec4(color, uOpacity);

    
}