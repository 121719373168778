import { useRef } from "react";
import * as THREE from "three";

import spotVertexShader from "./shaders/spot/vertex.glsl";
import spotFragmentShader from "./shaders/spot/fragment.glsl";
import { useFrame } from "@react-three/fiber";

export default function Spots({ coords, isMobile }) {
    const refs = useRef(new Array());

    // Material
    const material = new THREE.ShaderMaterial({
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
        transparent: true,
        fog: true,
        depthTest: true,
        depthWrite: false,
        uniforms: THREE.UniformsUtils.merge([THREE.UniformsLib["fog"]]),
        vertexShader: spotVertexShader,
        fragmentShader: spotFragmentShader,
    });

    useFrame((state) => {
        if (refs.current.length) {
            refs.current.map((item) => {
                if (item != null) {
                    if (isMobile) {
                        item.scale.x =
                            Math.sin(state.clock.elapsedTime * 5) * 0.5 + 0.5;
                        item.scale.y =
                            Math.sin(state.clock.elapsedTime * 5) * 0.5 + 0.5;
                    } else {
                        item.scale.x =
                            Math.sin(state.clock.elapsedTime * 5) * 2 + 2;
                        item.scale.y =
                            Math.sin(state.clock.elapsedTime * 5) * 2 + 2;
                    }
                }
            });
        }
    });

    return (
        <>
            {coords.map((coord, i) => {
                return (
                    i != 5 &&
                    i != 1 &&
                    i != 6 && (
                        <mesh
                            key={i}
                            ref={(element) => refs.current.push(element)}
                            position={[coord.x, coord.y - 0.122, coord.z]}
                            material={material}
                            rotation={[Math.PI * 0.5, 0, 0]}
                        >
                            <planeGeometry args={[0.4, 0.4]} />
                        </mesh>
                    )
                );
            })}
        </>
    );
}
