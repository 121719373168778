import "../Styles/Terms/disclosure.css";

export default function Disclosure() {
    const sections = [
        {
            title: "Processed Personal Data",
            text: "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:",
            list: [
                "●	Information regarding your location",
                "●	Information from your Device's phone book (contacts list)",
                "●	Pictures and other information from your Device's camera and photo library",
                "●	We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.",
                "●	You can enable or disable access to this information at any time, through Your Device settings.",
            ],
        },
        {
            title: "Purposes of Processing Personal Data",
            text: 'Your personal data will be processed within the framework of the following purposes based on the legal reasons stated in Article 5 of the Law: "it is necessary to process personal data belonging to the parties to the contract, provided that it is directly related to the establishment or performance of a contract", "data processing is mandatory for the legitimate interests of the data controller, provided that it does not harm the fundamental rights and freedoms of the person concerned" and "it is mandatory for the data controller to fulfil its legal obligation":',
            list: [
                "●	To provide and maintain our Service, including to monitor the usage of our Service. ",
                "●	To manage Your Account: to manage Your registration as a user of the Service. ",
                "●	For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
                "●	To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
                "●	To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
                "●	To manage Your requests: To attend and manage Your requests to Us.",
                "●	For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets.",
                "●	For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
            ],
        },
        {
            title: "Procedure for Collecting Your Personal Data",
            text: "Your personal data; in accordance with the law and good faith, accurately and up-to-date when necessary, for specific, clear and legitimate purposes, in connection with the purpose for which they are processed, in a limited and measured manner, in order to be kept for the required periods stipulated in the relevant legislation or for the purpose for which they are processed and for the required periods specified in other legislation, through the Company's websites, mobile applications, contact form / campaign form / application form or by collecting through the Company's call centre centre and by automatic or telephone recording.",
        },
        {
            title: "Transfer of Your Personal Data",
            text: 'Your personal data will be shared with the following persons based on the legal reasons stated in Article 5 of the Law: "provided that it is directly related to the establishment or performance of a contract, it is necessary to process personal data belonging to the parties to the contract", "provided that it does not harm the fundamental rights and freedoms of the person concerned, data processing is mandatory for the legitimate interests of the data controller" and "it is mandatory for the data controller to fulfil its legal obligation":',
            list: [
                "●	With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.",
                "●	For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
                "●	With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
                "●	With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.",
                "●	With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.",
            ],
        },
        {
            title: "Your Right to Apply as a Data Subject",
            text: "Pursuant to Article 11 of the Law regulating the rights of the data subject; your personal data;",
            list: [
                "a) to learn whether your personal data are processed or not,",
                "b) request information if processed,",
                "c) to learn the purpose of processing and whether it is used in accordance with its purpose,",
                "d) to know the third parties to whom it is transferred domestically / abroad,",
                "d) request correction if incomplete / incorrectly processed,",
                "e) To request deletion / destruction or anonymisation within the framework of the conditions stipulated in Article 7 of the KVKK,",
                "f) to request the third parties to whom it is transferred to be notified of the transactions made pursuant to subparagraphs (d) and (e) above,",
                "g) object to the occurrence of a result to your detriment due to analysis exclusively by automated systems,",
                "ğ) In case you suffer damage due to unlawful processing, you can submit your requests within the scope of the right to demand the compensation of the damage, ",
            ],
        },
    ];

    return (
        <>
            <div className="disclosure">
                <h1 className="disclosure__title">
                    DISCLOSURE TEXT ON THE PROTECTION OF PERSONAL DATA
                </h1>
                <p className="disclosure__text">
                    This disclosure text has been prepared by TDA Yapi Emlak
                    Organizasyon ve Ticaret Ltd Şti (Gamma Cities) as the data
                    controller in order to fulfil the disclosure obligation in
                    relation to data security within the scope of Article 10 of
                    the Law No. 6698 on the Protection of Personal Data ("Law".
                </p>
                {sections.map((section, index) => {
                    return (
                        <section key={index} className="disclosure__section">
                            <h2 className="disclosure__subtitle">
                                {section.title}
                            </h2>
                            <p className="disclosure__text">{section.text}</p>
                            {section.list && (
                                <ul className="disclosure__list">
                                    {section.list.map((item, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="disclosure__list--item"
                                            >
                                                {item}
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </section>
                    );
                })}
                <p className="disclosure__text">
                    together with the information identifying your identity, in
                    writing to TDA Yapi Emlak Organizasyon ve Ticaret Ltd Şti's
                    BARIŞ MAH. BELEDİYE CAD. GINZA LAVINYA NO: 30 İÇ KAPI NO:
                    262 BEYLİKDÜZÜ/İSTANBUL address in accordance with the
                    "Communiqué on Application Procedures and Principles to the
                    Data Controller" or to info@gammacities.com via registered
                    e-mail.
                </p>
                <p className="disclosure__text">
                    <h2>
                        Gamma Cities Communication Policy for WhatsApp Usage
                    </h2>
                    <h3>Introduction:</h3>
                    Gamma Cities values efficient communication while respecting
                    individual privacy and data protection. This policy outlines
                    three essential steps - acceptance for data and
                    communication permission, calling to ask for WhatsApp
                    message availability, and sending messages after obtaining
                    consent.
                    <h2>1. Step 1 - Acceptance for Data and Communication </h2>
                    Permission: a. Prior to initiating communication via
                    WhatsApp, employees must acquire explicit permission from
                    recipients for data usage and communication through this
                    platform. Permission must be documented and obtained through
                    a separate channel or established relationship where consent
                    has been granted. b. Employees are required to ensure that
                    recipients acknowledge and accept the use of their data and
                    the intent for communication via WhatsApp before proceeding
                    further.
                    <h2>
                        2. Step 2 - Calling and Asking WhatsApp Message
                        Availability:
                    </h2>
                    a. After obtaining acceptance for data usage and
                    communication permission, employees should initiate contact
                    through a call to confirm the recipient's availability and
                    willingness to receive messages via WhatsApp. b. During the
                    call, employees should inform the recipient about the nature
                    and frequency of intended WhatsApp messages, allowing the
                    recipient to confirm their consent or express any
                    preferences regarding communication.
                    <h2>3. Step 3 - Sending Messages:</h2>
                    a. Upon receiving explicit consent during the call,
                    employees may proceed to send messages via WhatsApp,
                    adhering to the agreedupon terms, frequency, and content
                    discussed during the call. b. Employees must respect the
                    recipient's preferences and refrain from sending messages if
                    consent is withdrawn or communication is declined during the
                    call. 4. Compliance and Enforcement: a. Non-compliance with
                    this policy may result in disciplinary action as per Gamma
                    Cities' policies and procedures. Conclusion: Gamma Cities
                    expects employees to follow the outlined steps for obtaining
                    consent and permission before initiating communication via
                    WhatsApp, respecting individual preferences, and ensuring
                    compliance with data protection and privacy guidelines
                </p>
            </div>
        </>
    );
}
