import * as THREE from "three";
import particlesVertexShader from './shaders/particles/vertex.glsl';
import particlesFragmentShader from './shaders/particles/fragment.glsl';
import { useFrame } from "@react-three/fiber";
import { useMemo } from "react";

export default function Particles() {
    const parameters = {}
    parameters.count = 200
    parameters.size = 0.02

    const geometry = new THREE.BufferGeometry()

    const {positions, scales, signs} = useMemo(()=>{

        const positions = new Float32Array(parameters.count * 3)
        const scales = new Float32Array(parameters.count * 1)
        const signs = new Float32Array(parameters.count * 1)
    
        for (let i = 0; i < parameters.count; i++) // Multiply by 3 for same reason
        {
            const i3 = i * 3
            positions[i3 + 0] = (Math.random() - 0.5) * 3 // Math.random() - 0.5 to have a random value between -0.5 and +0.5 for X
            positions[i3 + 1] = (Math.random() - 0.5) * 3 // Math.random() - 0.5 to have a random value between -0.5 and +0.5 for Y 
            positions[i3 + 2] = (Math.random() - 0.5) * 3 // Math.random() - 0.5 to have a random value between -0.5 and +0.5 for Z
    
            scales[i] = Math.random()
    
            signs[i] = Math.sign(Math.random() -0.5);
    
        }
        return {positions, scales, signs}
    }, [])
    
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values
    geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
    geometry.setAttribute('aSign', new THREE.BufferAttribute(signs, 1))

    /**
     * Material
     */
    const material = new THREE.ShaderMaterial({
        depthWrite: false,
        depthTest: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        vertexShader: particlesVertexShader,
        fragmentShader: particlesFragmentShader,
        uniforms:
        {
            uSize: { value: 500 },
            uTime: { value: 0 },
        },
    })

    useFrame((state)=>{
        material.uniforms.uTime.value = state.clock.getElapsedTime();
    }, [])

    return <>
        <points
            position={[0, 5, -20]}
            geometry={geometry}
            material={material}
            scale={10}
        >
        </points>
    </>
}