import { useGLTF } from "@react-three/drei";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { gsap, ScrollTrigger } from "../../../Scripts/gsap.js";

import * as THREE from "three";

import planeVertexShader from "./shaders/plane/vertex.glsl";
import planeFragmentShader from "./shaders/plane/fragment.glsl";
import { useState } from "react";

export default function Model2({ lenis }) {
    let isMobile = false;

    const model = useGLTF("/model/plane-random.glb");
    const planeMesh = useMemo(() => {
        const mesh = model.scene.children[0];
        mesh.rotation.y = -Math.PI / 2;

        return mesh;
    }, []);

    const [gltf, setGltf] = useState(planeMesh);

    // Material
    const material = new THREE.ShaderMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        fog: true,
        uniforms: THREE.UniformsUtils.merge([
            THREE.UniformsLib["fog"],
            {
                uTime: { value: 0 },
            },
        ]),
        vertexShader: planeVertexShader,
        fragmentShader: planeFragmentShader,
    });

    useEffect(() => {
        let mm = gsap.matchMedia();
        mm.add("(max-width: 930px)", () => {
            isMobile = true;
        });
        planeMesh.material = material;
        setGltf(planeMesh);

        return () => {
            useGLTF.clear("/model/plane-random.glb");
        };
    }, []);

    const experience = document.getElementById("Road MAP-section");

    const handleWheelEvent = (event) => {
        let deltaY = event.deltaY;
        if (event.cancelable) event.preventDefault();

        lerp.target = ScrollTrigger.getById("experience").progress;

        if (lerp.current >= 0.9999) {
            experience.removeEventListener("wheel", handleWheelEvent);
            experience.removeEventListener("touchmove", handleWheelEvent);
        }
        if (lerp.current == 0 && deltaY < 0) {
            experience.removeEventListener("wheel", handleWheelEvent);
            experience.removeEventListener("touchmove", handleWheelEvent);
        }
    };

    const lerp = {
        current: 0,
        target: 0,
        ease: 0.2,
    };
    let position = new THREE.Vector3(0, 0, 0);
    let lookAtPosition = new THREE.Vector3(0, 0, 0);
    const curve = new THREE.CatmullRomCurve3([
        new THREE.Vector3(0, 0.5, 10),
        new THREE.Vector3(-0.5, 0.3, 8.46),
        new THREE.Vector3(0, 0.5, 6.92),
        new THREE.Vector3(1, 0.7, 5.38),
        new THREE.Vector3(1, 0.7, 3.84),
        new THREE.Vector3(0.5, 0.5, 2.3),
        new THREE.Vector3(1, 0.7, 0.76),
        new THREE.Vector3(1.5, 0.8, -0.78),
        new THREE.Vector3(1, 0.5, -2.32),
        new THREE.Vector3(1, 0.7, -3.86),
        new THREE.Vector3(1, 0.7, -4),
    ]);

    useFrame((state) => {
        if (curve) {
            const max = 0.00001;
            lerp.current = gsap.utils.interpolate(
                lerp.current,
                lerp.target,
                lerp.ease
            );
            lerp.current = gsap.utils.clamp(0, 1 - max, lerp.current);

            if (ScrollTrigger.getById("experience")) {
                lerp.target = ScrollTrigger.getById("experience").progress;
            } else {
                lerp.target = 0;
            }

            curve.getPointAt(lerp.current, position);
            curve.getPointAt(
                gsap.utils.clamp(0, 1, lerp.current + max),
                lookAtPosition
            );
            state.camera.position.copy(position);
            state.camera.lookAt(lookAtPosition);
        }
        material.uniforms.uTime.value = state.clock.elapsedTime;
    });

    useLayoutEffect(() => {
        ScrollTrigger.getAll().forEach((el) => el.enable());
        setTimeout(() => {
            ScrollTrigger.create({
                id: "experience",
                trigger: experience,
                start: "top top",
                end: "700% top",
                pin: true,
                onEnter: () => {
                    experience.addEventListener("wheel", handleWheelEvent, {
                        passive: false,
                    });
                    experience.addEventListener("touchmove", handleWheelEvent, {
                        passive: false,
                    });
                },

                onLeave: () => {
                    lerp.current = 0.995;
                    lerp.target = 0.995;
                    experience.removeEventListener("wheel", handleWheelEvent);
                    experience.removeEventListener(
                        "touchmove",
                        handleWheelEvent
                    );
                },

                onEnterBack: () => {
                    lerp.current = 0.995;
                    lerp.target = 0.995;
                    experience.addEventListener("wheel", handleWheelEvent);
                    experience.addEventListener("touchmove", handleWheelEvent);
                },

                onLeaveBack: () => {
                    lerp.current = 0;
                    lerp.target = 0;
                },
            });
        }, 3000);
        ScrollTrigger.refresh();
        return () => {
            ScrollTrigger.getAll().forEach((el) => {
                if (el.vars.id != "sticky-beAPart") el.disable();
            });
        };
    }, []);

    return (
        <>
            <primitive object={gltf} dispose={null} />
        </>
    );
}

useGLTF.preload("/model/plane-random.glb");
