#include <fog_pars_fragment>

varying vec2 vUv;
#define PI 3.1415926535897932384626433832795

void main()
{
    vec3 color = vec3(0.);
    vec2 uv = vUv;
    
    float circle = 1. - 2.* length(uv-0.5);
    // circle = 0.143/ length(uv-0.5);
    circle = 0.031/ length(uv-0.5);
    color = mix(vec3(0.), vec3(0.0588, 0.7568, 0.7568), circle);

    gl_FragColor = vec4(color, 1.) * circle;

    #include <fog_fragment>
    
}