import { Canvas } from "@react-three/fiber";
import { useLayoutEffect, useMemo, useRef } from "react";
import { gsap, ScrollTrigger } from "../../../Scripts/gsap.js";
import * as THREE from "three";

import Spots from "./Spots.jsx";
import ShapesText from "./ShapesText.jsx";
import Model2 from "./Model2.jsx";

import "../../../Styles/Experiences/roadmap.css";

export default function Roadmap({ lenis, isMobile }) {
    const gif = useRef();
    const coords = useMemo(() => {
        return [
            new THREE.Vector3(-0.5, 0.3, 8.46),
            new THREE.Vector3(0, 0.5, 6.92),
            new THREE.Vector3(1, 0.7, 5.38),
            new THREE.Vector3(1, 0.7, 3.84),
            new THREE.Vector3(0.5, 0.5, 2.3),
            new THREE.Vector3(1, 0.4, 0.76),
            new THREE.Vector3(1.5, 0.8, -0.78),
            new THREE.Vector3(1, 0.5, -2.32),
            new THREE.Vector3(1, 0.7, -3.86),
        ];
    }, []);

    useLayoutEffect(() => {
        ScrollTrigger.create({
            id: "gif",
            trigger: gif.current,
            start: "top top",
            onEnter: () => {
                gsap.to(gif.current, {
                    opacity: 0,
                    duration: 0.5,
                });
            },

            onEnterBack: () => {
                gsap.to(gif.current, {
                    opacity: 0,
                    duration: 0.5,
                });
            },

            onLeaveBack: () => {
                gsap.to(gif.current, {
                    opacity: 1,
                    duration: 0.5,
                });
            },
        });
    }, []);

    return (
        <>
            <div className="roadmap" id="Road MAP-section">
                <div ref={gif} className="roadmap__gif">
                    <img src="/videos/road-map.gif" alt="roadmap" />
                </div>
                <Canvas
                    camera={{
                        position: [0.25, 0.25, 10],
                        fov: 75,
                        near: 0.1,
                        far: 100,
                    }}
                    flat
                >
                    <fog attach="fog" args={["#151626", 1, 5]} />
                    <color attach="background" args={["#151626"]} />

                    <Model2 lenis={lenis} />

                    <group position-y={-0.01}>
                        <ShapesText coords={coords} />
                        <Spots coords={coords} isMobile={isMobile} />
                    </group>
                </Canvas>
            </div>
        </>
    );
}
