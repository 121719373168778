import React from 'react';
import './Loader.css';
import logoImage from './Logo.png';

const Loader = () => {
  return (
    <div className="loader-container">
      <section className="upper-section">
        <div className="upper-content"></div>
      </section>
      <section className="lower-section">
        <div className="text-content">
          <h2>GAMMA CITIES </h2>
          <span className="subheading">Keyboard Metaverse Guide</span>
          <div className="typing-container">
            <div className="typing-demo">Connecting ...</div>
          </div>
        </div>
        <div className="logo-content">
          <img src={logoImage} alt="Logo" className="logo" />
        </div>
      </section>
    </div>
  );
};

export default Loader;
