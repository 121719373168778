import { Link } from "wouter";
import { LoginForm, RegisterForm, Slideshow } from "./Forms";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import '../Styles/Auth/register.css'


export default function Register() {

    const [[image, direction], setImage] = useState([0, 0]);
    const [isIntrested, setIsIntrested] = useState(new URLSearchParams(window.location.search).get('intrested'))
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 930px)").matches);


    const images = [
        "/images/bc-images/slider1.jpg",
        "/images/bc-images/slider2.jpg",
        "/images/bc-images/slider3.jpg",
    ]

    const imageIndex = Math.abs(image % images.length);

    // const urlParams = new URLSearchParams(window.location.search);
    // const intrested = urlParams.get('intrested');

    useEffect(() => {
        setTimeout(() => {
            setImage([image + 1, 1]);
        }, 4000)
    }, [image])

    return <>
        <section className="login">
            <AnimatePresence>
                {
                    isIntrested &&
                    <motion.div
                        initial={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1
                        }}
                        exit={{
                            opacity: 0
                        }}
                        className="register__message"
                    >
                        <div
                            onClick={() => setIsIntrested(false)}
                            className="register__message--bg"
                        />
                        <div className="register__message--content">
                            <div className="register__message--icon">
                                <svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455ZM11 14V16H13V14H11ZM8.56731 8.81346L10.5288 9.20577C10.6656 8.51823 11.2723 8 12 8C12.8284 8 13.5 8.67157 13.5 9.5C13.5 10.3284 12.8284 11 12 11H11V13H12C13.933 13 15.5 11.433 15.5 9.5C15.5 7.567 13.933 6 12 6C10.302 6 8.88637 7.20919 8.56731 8.81346Z" fill="rgba(255,255,255,1)"></path></svg>
                            </div>
                            <p className="register__message--subtitle">
                                You are not registered yet!
                            </p>
                            <button 
                                className="register__message--close"
                                onClick={()=> setIsIntrested(false)}
                            >

                            </button>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            <div className="login__content--wrapper">
                <div className="login__content">
                    <Link href="/" >
                        <img src="images/logo.png" alt="" className="login__logo" />
                    </Link>
                    <h1 className="login__title">
                        Register
                    </h1>
                    <RegisterForm />

                </div>
            </div>
            {
                !isMobile &&
            <div className="login__slider">
                <Slideshow image={images[imageIndex]} />
            </div>
            }
        </section>
    </>
}