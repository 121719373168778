import { Link } from "wouter";
import { LoginForm, Slideshow } from "./Forms";
import { useEffect, useState } from "react";
import { create } from "zustand";
import Cookies from "js-cookie";

import "../Styles/Auth/login.css";

export const useLogin = create((set) => ({
    user: Cookies.get("token") ? Cookies.get("token") : null,
    loggedIn: (newUser) => {
        if (newUser) {
            // Define the duration in seconds (e.g., 1 hour = 3600 seconds)
            // two weeks
            const durationInSeconds = 3600 * 24 * 14;

            // Calculate the expiration date
            const expirationDate = new Date(
                Date.now() + durationInSeconds * 1000
            );

            document.cookie = `token=${
                newUser.token ? newUser.token : newUser
            }; Domain=.gammacities.com; Path=/; Expires=${expirationDate.toUTCString()}; Secure`;

            // Fetch user data
            fetch("https://api.gammacities.com/api/auth/me", {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${
                        newUser.token ? newUser.token : newUser
                    }`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    const { email, username } = data;
                    localStorage.setItem(
                        "userData",
                        JSON.stringify({ email, username })
                    );
                })
                .catch((error) => console.error("Error:", error));
        }
        set({ user: newUser });
    },
}));

export const useModal = create((set) => ({
    isModalVisible: true,
    changeModal: (status) => {
        set({ isModalVisible: status });
    },
}));

export default function Login() {
    const [[image, direction], setImage] = useState([0, 0]);
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 930px)").matches
    );

    const images = [
        "/images/bc-images/slider1.jpg",
        "/images/bc-images/slider2.jpg",
        "/images/bc-images/slider3.jpg",
    ];

    const imageIndex = Math.abs(image % images.length);

    useEffect(() => {
        setTimeout(() => {
            setImage([image + 1, 1]);
        }, 4000);
    }, [image]);

    return (
        <>
            <section className="login">
                <div className="login__content--wrapper">
                    <div className="login__content">
                        <Link href="/">
                            <img
                                src="images/logo.png"
                                alt=""
                                className="login__logo"
                            />
                        </Link>
                        <h1 className="login__title">Login</h1>
                        <LoginForm />
                    </div>
                </div>
                {!isMobile && (
                    <div className="login__slider">
                        <Slideshow image={images[imageIndex]} />
                    </div>
                )}
            </section>
        </>
    );
}
