import { Link } from "wouter";
import { ResetPasswordRequest, ResetPasswordValidate, Slideshow } from "./Forms";
import { useEffect, useState } from "react";

export default function ResetPassword() {

    const [validate, setIsValidate] = useState(new URLSearchParams(window.location.search).get('token'));
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 930px)").matches);


    const [[image, direction], setImage] = useState([0, 0]);

    const images = [
        "/images/bc-images/slider1.jpg",
        "/images/bc-images/slider2.jpg",
        "/images/bc-images/slider3.jpg",
    ]

    const imageIndex = Math.abs(image % images.length);

    useEffect(() => {
        setTimeout(() => {
            setImage([image + 1, 1]);
        }, 4000)
    }, [image])

    return <>
        <section className="login">
            <div className="login__content--wrapper">
                <div className="login__content">
                    <Link href="/" >
                        <img src="images/logo.png" alt="" className="login__logo" />
                    </Link>
                    {
                        validate ?
                            <>
                                <h1 className="login__title">
                                    Create new password
                                </h1>
                                <p className="login__text">
                                    Your password must be unique from those previously used.
                                </p>
                                <ResetPasswordValidate token={validate} />
                            </>
                            :
                            <>
                                <h1 className="login__title">
                                    Forgot Password?
                                </h1>
                                <p className="login__text">
                                    Don't worry! it occurs. Please enter the email address linked with your account.
                                </p>
                                <ResetPasswordRequest />
                            </>
                    }

                </div>
            </div>
            {
                !isMobile &&
                <div className="login__slider">
                    <Slideshow image={images[imageIndex]} />
                </div>
            }
        </section>
    </>
}