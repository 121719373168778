
#include <fog_pars_vertex>

uniform float uTime;

varying vec2 vUv;

void main()
{
    #include <begin_vertex>
    #include <project_vertex>
    #include <fog_vertex>

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    modelPosition.y += sin(modelPosition.x * 5. + uTime) * 0.05 ;
    modelPosition.y += sin(modelPosition.y * 5. + uTime) * 0.05 ;
    
    gl_Position = projectionMatrix * viewMatrix * modelPosition;

    vUv = uv;
}