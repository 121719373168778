import './style.css';
import { useEffect, useState } from "react";
import Home from "./Pages/Home.jsx";


export default function Linktree() {
    const [mouseCoordinate, setMouseCoordinate] = useState({
        x: 0,
        y: 0,
    })


    useEffect(() => {
        const handleMouseMove = (e) => {
            const x = e.x / window.innerWidth - 0.5;
            const y = e.y / window.innerHeight - 0.5;
            setMouseCoordinate({
                x,
                y
            })
        }
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        }
    }, [])
    return <>
        <div className="linktree__wrapper">
            <Home mouse={mouseCoordinate} />
        </div>
    </>
}