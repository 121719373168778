import { Canvas } from "@react-three/fiber";
import Earth from "./Earth.jsx";
import { useEffect } from "react";
import { create } from 'zustand'
import { AnimatePresence, motion } from "framer-motion";
import Particles from "./Particles.jsx";

import '../../../Styles/Experiences/planets.css';
import '../../../Styles/Experiences/range.css';


export const useExploreStore = create((set) => ({
    explore: false,
    changeExplore: () => set((state) => ({ explore: !state.explore })),
    notExplore: () => set((state) => ({ explore: false })),
    intrested: false,
    isIntrested: () => set((state) => ({ intrested: true })),
    isNotIntrested: () => set((state) => ({ intrested: false })),
}))

export default function Planets({ lenis, isMobile }) {

    const notExplore = useExploreStore((state) => state.notExplore);
    const exploring = useExploreStore((state) => state.explore);
    const displayIntrestedMessage = useExploreStore((state) => state.intrested);
    const isNotIntrested = useExploreStore((state) => state.isNotIntrested);

    useEffect(() => {
        const checkifScroll = () => {
            if (lenis.isScrolling && Math.abs(lenis.velocity) > 2) {
                notExplore()
            }
            requestAnimationFrame(checkifScroll)
        }
        checkifScroll()
    }, [exploring])

    return <>
        <section className="dummy" id="Home-section">
            <h1 style={{ opacity: "0" }} className="hero__title">UNLIMITED LIFE</h1>
            <h1 style={{ opacity: "0" }} className="hero__title--second">METAVERSE</h1>
        </section>
        <AnimatePresence>
            {
                displayIntrestedMessage &&
                <motion.div
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    className="intrested__message"
                >
                    <div
                        onClick={() => isNotIntrested()}
                        className="intrested__message--bg"
                    />
                    <div className="intrested__message--content">
                        <h1 className="intrested__message--title">
                            Thank You
                        </h1>
                        <h2 className="intrested__message--subtitle">
                            You are whitlisted now!
                            <br />
                            And will be informed for the presale soon!
                        </h2>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
        <div className="planets" id="planets">
            <Canvas
                camera={{
                    position: [0.25, 0.25, 10],
                    fov: 45,
                    near: 0.1,
                    far: 100
                }}
            >
                <Particles />
                <group>
                    <Earth lenis={lenis} isMobile={isMobile} />
                </group>
            </Canvas>
        </div>
    </>
}