import { VerifyForm, Slideshow } from "./Forms";
import { useEffect, useState } from "react";

export default function Verify() {

    const [[image, direction], setImage] = useState([0, 0]);
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 930px)").matches);


    const images = [
        "/images/bc-images/slider1.jpg",
        "/images/bc-images/slider2.jpg",
        "/images/bc-images/slider3.jpg",
    ]

    const imageIndex = Math.abs(image % images.length);

    useEffect(() => {
        setTimeout(() => {
            setImage([image + 1, 1]);
        }, 4000)
    }, [image])

    return <>
        <section className="login">
            <div className="login__content--wrapper">
                <div className="login__content">
                    {/* <Link href="/" > */}
                        <img src="images/logo.png" alt="" className="login__logo" />
                    {/* </Link> */}
                    <h1 className="login__title">
                        Please verify your email before login.
                    </h1>
                    <VerifyForm />

                </div>
            </div>
            {
                !isMobile &&
            <div className="login__slider">
                <Slideshow image={images[imageIndex]} />
            </div>
            }

        </section>
    </>
}