import { useRef, useEffect } from "react";
import axios from "axios";
import { useLogin } from "./Login.jsx";

const proxy = "https://api.gammacities.com/api";

export default function GoogleLoginIcon() {
    const loggedIn = useLogin((state) => state.loggedIn);
    const reference = localStorage.getItem("gamma-reference");
    const googleBtn = useRef(null);
    function handleCredentialResponse(response) {
        const data = new Object()
        data.token = response.credential

        const referrerCode = new URLSearchParams(
            window.location.search
        ).get("referralCode");

        if(referrerCode){
            data.referrerCode = referrerCode
        }
        const config = {
            method: "post",
            url: `${proxy}/auth/google-auth`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
            withCredentials: true,
        };
        axios(config).then((response) => {
            const token = response.data.token.split(".")[1];
            const data = JSON.parse(atob(token));
            if (data.isVer) {
                loggedIn(response.data);

                const redirect_url = new URLSearchParams(
                    window.location.search
                ).get("redirect_url");
                if (redirect_url) {
                    window.location.href = `${redirect_url}`;
                }
                reference ? navigate(`${reference}`) : navigate("/");
            }
        });
    }
    const initGoogleButton = function () {
        window.google.accounts.id.initialize({
            client_id:
                "304315501457-opg8dvor009l3o5n2hv6af20jvnvk9fg.apps.googleusercontent.com",
            callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
            googleBtn.current,
            { theme: "outline", size: "large" } // customization attributes
        );
        window.google.accounts.id.prompt(); // also display the One Tap dialog
    };

    useEffect(() => {
        if(typeof window.google !== "undefined"){
            initGoogleButton();
        }
    }, []);
    return (
        <div style={{ width: "350px", margin: "auto", paddingBottom: "10px"  }}>
            <div ref={googleBtn} style={{ textAlign: "-webkit-center" }}></div>
        </div>
    );
}
