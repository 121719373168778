import { useEffect, useState, lazy, Suspense } from "react";
import Lenis from "@studio-freight/lenis";
import axios from "axios";
import { ScrollTrigger } from "./Scripts/gsap.js";
import { useLocation } from "wouter";
import Cookies from "js-cookie";

import Planets from "./Components/Experiences/Planets/Planets.jsx";
import Roadmap from "./Components/Experiences/Roadmap/Roadmap.jsx";

import Header from "./Components/Header.jsx";
import Footer from "./Components/Footer.jsx";

import Background from "./Components/Background.jsx";
import Loader from "./Components/Home/Loader.jsx";

// import About from "./Components/Home/About.jsx";
// import PropertiesSection from "./Components/Home/PropertiesSection.jsx";
// import Team from "./Components/Home/Team.jsx";
// import Community from "./Components/Home/Community.jsx";
// import Business from "./Components/Home/Business.jsx";
// import Contents from "./Components/Home/Contents.jsx";

const About = lazy(() => import("./Components/Home/About.jsx"));
// const Roadmap = lazy(() => import("./Components/Experiences/Roadmap/Roadmap.jsx"));
const PropertiesSection = lazy(() =>
    import("./Components/Home/PropertiesSection.jsx")
);
const Community = lazy(() => import("./Components/Home/Community.jsx"));
const Business = lazy(() => import("./Components/Home/Business.jsx"));
const Contents = lazy(() => import("./Components/Home/Contents.jsx"));

import { useLogin } from "./Auth/Login.jsx";

export default function Home() {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 930px)").matches
    );
    const [_, setLocation] = useLocation();

    const reference =
        new URLSearchParams(window.location.search).get("reference") ??
        localStorage.getItem("gamma-reference");

    const loaded = localStorage.getItem("loaded") ?? false;

    const lenis = new Lenis({
        smoothTouch: true,
        lerp: 0.05,
        normalizeWheel: true,
    });
    const loggedIn = useLogin((state) => state.loggedIn);
    const isUserLogedIn = useLogin((state) => state.user);

    useEffect(() => {
        // to prevent to keep scrolling position on refresh
        window.onbeforeunload = function () {
            lenis.scrollTo(0, { immediate: true, force: true });
            // gsap.to(window, { duration: 0, scrollTo: 0 });
        };
        lenis.scrollTo(0, { immediate: true });
        // gsap.to(window, { duration: 0, scrollTo: 0 });

        function raf(time) {
            lenis.raf(time);
            ScrollTrigger.update();
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        localStorage.setItem("loaded", true);

        // set initial value
        const mediaWatcher = window.matchMedia("(max-width: 930px)");
        setIsMobile(mediaWatcher.matches);

        //watch for updates
        function updateIsNarrowScreen(e) {
            setIsMobile(e.matches);
        }
        mediaWatcher.addEventListener("change", updateIsNarrowScreen);

        if (reference) {
            if (isUserLogedIn) {
                setLocation(`/${reference}`);
            } else {
                localStorage.setItem("gamma-reference", reference);
                setLocation("/login");
            }
        }

        return () => {
            lenis.stop();
            lenis.destroy();
            mediaWatcher.removeEventListener("change", updateIsNarrowScreen);
        };
    }, []);

    return (
        <>
            <div>
                {!loaded && <Loader lenis={lenis} />}
                <Background />

                <Header lenis={lenis} isMobile={isMobile} />
                <Planets lenis={lenis} isMobile={isMobile} />
                <Suspense fallback={null}>
                    <About isMobile={isMobile} />
                    <PropertiesSection isMobile={isMobile} />
                    <Roadmap lenis={lenis} isMobile={isMobile} />
                    {/* <Team lenis={lenis} isMobile={isMobile} /> */}
                    <Community isMobile={isMobile} lenis={lenis} />
                    {/* <Business isMobile={isMobile} /> */}
                    <Footer isMobile={isMobile} />
                    <Contents lenis={lenis} isMobile={isMobile} />
                </Suspense>
            </div>
        </>
    );
}
