import { Link } from "wouter";
import privacy from "/pdf/PRIVACY_POLICY.pdf";
import copyright from "/pdf/TERMS_AND_CONDITIONS.pdf";

import "../Styles/footer.css";

export default function Footer() {
    return (
        <>
            <section className="footer">
                <div className="container ">
                    <Link href="/">
                        <a className="footer__logo">
                            <img src="/images/logo.png" alt="" />
                        </a>
                    </Link>
                    <ul className="footer__links">
                        {/* <li className="footer__link" target="_blank">
                            <a
                                href="/pdf/FAQ.pdf"
                                target="_blank"
                                className="footer__link"
                            >
                                FAQ
                            </a>
                        </li>

                        <li className="footer__link">
                            OpenSea
                            <div className="menu__item--ComingSoon">
                                Coming soon
                            </div>
                        </li> */}
                    </ul>
                    <p className="footer__text">
                        Gamma Cities is a registered trademark. All website
                        content is &nbsp;
                        <a
                            className="footer__link footer__pdf"
                            href={copyright}
                            target="_blank"
                        >
                            copyright
                        </a>
                        &nbsp;© 2024.&nbsp;Explore our&nbsp;
                        <a
                            className="footer__link footer__pdf"
                            href={privacy}
                            target="_blank"
                        >
                            {/* Privacy Policy */}
                            Whatsapp Privacy Policy
                        </a>
                        {","}&nbsp;
                        <a
                            href="/disclosure"
                            target="_blank"
                            className="footer__link footer__pdf"
                        >
                            {/* Terms of Use */}
                            Communication Policy
                        </a>
                        {", and"}&nbsp;
                        <a
                            href="/pdf/PDPL.pdf"
                            target="_blank"
                            className="footer__link footer__pdf"
                        >
                            PDPL
                        </a>
                        {"."}
                    </p>
                </div>
            </section>
        </>
    );
}
