import { useEffect, useRef, useState } from "react"
import { gsap } from "gsap";

import '../../Styles/Home/loader.css'


export default function Loader({ lenis }) {
    const loader = useRef();
    const loaderRef = useRef();
    const countDownRef = useRef();
    const logo = useRef();
    
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let rest = 100;
        let actualProgress = 0;
        setTimeout(() => {
            const current =  parseInt(Math.random() * 0.25 * rest);
            rest -= current;
            actualProgress += current;
            setProgress(actualProgress);
        }, 500);
        setTimeout(() => {
            const current =  parseInt(Math.random() * 0.5 * rest);
            rest -= current;
            actualProgress += current;
            setProgress(actualProgress);
        }, 1000);
        setTimeout(() => {
            const current =  parseInt(Math.random() * 0.75 * rest);
            rest -= current;
            actualProgress += current;
            setProgress(actualProgress);
        }, 1500);
        setTimeout(() => {
            const current =  parseInt(Math.random() * rest);
            rest -= current;
            actualProgress += current;
            setProgress(actualProgress);
        }, 2500);
        setTimeout(() => {
            const current = 100;
            rest -= rest;
            actualProgress = current;
            setProgress(actualProgress);
        }, 4000);

        return () => {
            gsap.to(loader.current, {
                opacity: 1,
                duration: 0
            })
            gsap.to(loader.current, {
                display: "block",
                duration: 0
            })
        }
    }, []);

    useEffect(() => {
        lenis.stop()
        gsap.to(loaderRef.current, {
            width: `${progress}%`,
            duration: 3,
            ease: 'power4.inOut',
            onUpdate: () => {
                countDownRef.current.innerHTML = `${Math.floor(
                    gsap.getProperty(loaderRef.current, 'width')
                )}%`;
            },
            onComplete: ()=>{
                const progressing = gsap.getProperty(loaderRef.current, 'width')
                if(progressing === 100){
                    window.setTimeout(()=>{
                        lenis.start()
                    }, 1000)
                    const tl = gsap.timeline();

                    tl.to(logo.current, {
                        opacity: 0,
                        duration: .5
                    })
                    tl.to(loader.current, {
                        opacity: 0,
                        duration: .5
                    })
                    tl.to(loader.current, {
                        display: "none",
                        duration: 0
                    })
                    lenis.scrollTo(0, {immediate: true});
                }
            }
        });
    }, [progress]);

    return <>
        <div ref={loader} className="loader">
            <div className="loader__wrapper">

                <div ref={logo} className="loader__logo">
                    <img src="/images/logo.png" alt="logo" />
                </div>
                <div ref={countDownRef} className="loader__countDown">
                    0%
                </div>
                <div className="loader__bar">
                    <div ref={loaderRef} className="loader__progress-bar"></div>
                </div>
                <div className="loader__text">
                    Loading
                </div>
            </div>
        </div>
    </>
}