import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "wouter";
import { useLogin } from "../Auth/Login.jsx";

import "../Styles/header.css";

export default function Header({ lenis, isMobile }) {
    const [isMobileClicked, setIsMobileClicked] = useState(false);
    const [userData, setUserData] = useState({ email: null, username: null });
    const header = useRef();
    const toggleUp = useRef();
    const toggleDown = useRef();
    const toggleWrapper = useRef();
    const menuSmall = useRef();

    const span1 = useRef();
    const span2 = useRef();
    const span3 = useRef();

    const user = useLogin((state) => state.user);
    const loggedIn = useLogin((state) => state.loggedIn);

    useEffect(() => {
        if (user) {
            const storedUserData = localStorage.getItem("userData");
            if (storedUserData) {
                setUserData(JSON.parse(storedUserData));
            }
        }
    }, [user]);

    const [location, setLocation] = useLocation();

    const handleClickMobile = () => {
        if (!isMobileClicked) {
            header.current.style.background = "none";
            toggleUp.current.style.transform =
                "translate(-50%, -50%) rotate(405deg)";
            toggleUp.current.style.width = "20px";
            toggleUp.current.style.top = "50%";
            toggleDown.current.style.transform =
                "translate(-50%, -50%) rotate(495deg)";
            toggleDown.current.style.width = "20px";
            toggleDown.current.style.top = "50%";
            lenis.stop();
        } else {
            header.current.style.background = "rgba(76.5, 155, 240, .2)";
            toggleUp.current.style.transform = "";
            toggleUp.current.style.width = "";
            toggleUp.current.style.top = "60%";
            toggleDown.current.style.transform = "";
            toggleDown.current.style.width = "";
            toggleDown.current.style.top = "40%";
            lenis.start();
        }
        setIsMobileClicked(!isMobileClicked);
        menuSmall.current.classList.toggle("menu__small--apear");
    };

    const [openProfile, setOpenProfile] = useState(false);

    return (
        <>
            {isMobile && (
                <div ref={menuSmall} className="menu__small">
                    <div className="menu__small--flex">
                        {user ? (
                            <div className="menu__small--item">
                                <button
                                    className="header__authentification--logout"
                                    onClick={() => {
                                        // localStorage.removeItem("gamma-user");
                                        document.cookie =
                                            "token=; Domain=.gammacities.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure";
                                        loggedIn(null);
                                        setLocation(location);

                                        // Delete user data
                                        localStorage.removeItem("userData");
                                        setUserData({
                                            email: null,
                                            username: null,
                                        });
                                    }}
                                >
                                    Log Out
                                </button>
                                <div className="menu__small--border" />
                            </div>
                        ) : (
                            <>
                                <div className="menu__small--item">
                                    <a
                                        href="https://platform.gammaassets.com/auth/login"
                                        className="header__authentification"
                                    >
                                        Log in
                                    </a>
                                    <div className="menu__small--border" />
                                </div>
                                <div className="menu__small--item">
                                    <a
                                        href="https://platform.gammaassets.com/auth/register"
                                        className="header__authentification"
                                    >
                                        Registerdasdas
                                    </a>
                                    <div className="menu__small--border" />
                                </div>
                            </>
                        )}
                        <div className="menu__small--item">
                            <a
                                href="https://gammaassets.com/"
                                target="_blank"
                                className="header__authentification"
                            >
                                Gamma assets
                            </a>
                            <div className="menu__small--border" />
                        </div>
                    </div>
                </div>
            )}
            <div ref={header} className="row header__flex">
                <Link href="/">
                    <a className="header__item">
                        <img src="/images/logo.png" alt="" />
                    </a>
                </Link>
                {isMobile ? (
                    <div ref={toggleWrapper} className="menu__toggle--wrapper">
                        <div
                            onClick={handleClickMobile}
                            id="menu_toggle"
                            className="menu__toggle"
                        >
                            <span className="menu__toggle--spans">
                                <span
                                    ref={span1}
                                    className="menu__toggle--span menu__toggle--yellow"
                                ></span>
                                <span
                                    ref={span2}
                                    className="menu__toggle--span menu__toggle--green"
                                ></span>
                                <span
                                    ref={span3}
                                    className="menu__toggle--span menu__toggle--aqua"
                                ></span>
                            </span>

                            <div
                                ref={toggleUp}
                                className="menu__toggle--icon--up"
                            />
                            <div
                                ref={toggleDown}
                                className="menu__toggle--icon--down"
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <ul className="header__links">
                            <li>
                                <a
                                    href="https://gammaassets.com/"
                                    target="_blank"
                                    className="header__link"
                                >
                                    Gamma assets
                                </a>
                            </li>
                            <li>
                                <a className="header__link header__link--cs">
                                    <span>Gamma Verse</span>
                                    <div
                                        className="menu__item--ComingSoon"
                                        style={{
                                            width: "max-content",
                                            left: "50%",
                                            top: "125%",
                                            cursor: "none",
                                        }}
                                    >
                                        Coming soon
                                    </div>
                                </a>
                            </li>
                        </ul>

                        {user ? (
                            <div className="header__authentifications">
                                <div className="header__authentification">
                                    <button
                                        className="header__authentification--profile"
                                        onClick={() =>
                                            setOpenProfile(!openProfile)
                                        }
                                    >
                                        <svg
                                            width={25}
                                            height={25}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                d="M4 22C4 17.5817 7.58172 14 12 14C16.4183 14 20 17.5817 20 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
                                                fill="rgba(255,255,255,1)"
                                            ></path>
                                        </svg>
                                        {openProfile && (
                                            <div className="header__authentification--infos">
                                                <p>{userData.email}</p>
                                                <p>{userData.username}</p>

                                                <button
                                                    className="header__authentification--logout"
                                                    onClick={() => {
                                                        // localStorage.removeItem("gamma-user");
                                                        document.cookie =
                                                            "token=; Domain=.gammacities.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure";
                                                        loggedIn(null);
                                                        setLocation(location);
                                                    }}
                                                >
                                                    Log Out
                                                </button>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="header__authentifications">
                                <a
                                    href="https://platform.gammaassets.com/auth/login"
                                    className="header__authentification"
                                >
                                    Log in
                                </a>
                                <a
                                    href="https://platform.gammaassets.com/auth/register"
                                    className="header__authentification"
                                >
                                    Register
                                </a>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
