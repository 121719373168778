import { motion } from "framer-motion";

export default function Links() {
    const links = [
        {
            name: "Website",
            link: "https://gammacities.com/",
        },
        {
            name: "X",
            link: "https://twitter.com/GammaCities",
        },
        {
            name: "Telegram",
            link: "https://t.me/gammacities",
        },
        {
            name: "Linkedin",
            link: "https://www.linkedin.com/company/gamma-cities",
        },
        {
            name: "Discord",
            link: "https://discord.gg/gammacities",
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com/gammacities/",
        },
        {
            name: "TikTok",
            link: "https://www.tiktok.com/@gammacities?_t=8dQlaH4Agnl&_r=1",
        },
        {
            name: "Youtube",
            link: "https://www.youtube.com/@gammacities",
        },
        {
            name: "White Paper",
            link: "https://gammacities.com/pdf/whitePaper.pdf",
        },
    ];
    const containerVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                type: "spring",
            },
        },
    };
    return (
        <>
            <div className="linktree__logo-container">
                <img
                    className="linktree__logo"
                    src="/Linktree/figure/light-logo.png"
                    alt=""
                />
            </div>
            <div className="linktree">
                {links.map((item, key) => {
                    return (
                        <motion.a
                            whileHover={{
                                scale: 1.125,
                                transition: {
                                    type: "spring",
                                    stiffness: 400,
                                },
                            }}
                            className="Linktree__link"
                            key={key}
                            href={item.link}
                            target="_blank"
                        >
                            {item.name}
                        </motion.a>
                    );
                })}
            </div>
        </>
    );
}
